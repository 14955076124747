<template>
  <div id="vertriebsmaterialien" class="secondary-page sales-materials">
    <b-container>
      <div v-if="!isSubPage" class="go-back">
        <ButtonComponent
          btnStyle="link-primary"
          behavior="internalLink"
          link="/"
        >
          <Icon name="arrow-full-left" :width="15" :heght="14" />
          <span class="ml-2">Zurück</span>
        </ButtonComponent>
      </div>
      <NavCards v-if="!isSubPage" :cards="cards" :max-cols="4" />

      <router-view></router-view>
    </b-container>
    <BackToTop />
  </div>
</template>

<script>
import Icon from '@/components/Icon'
import ButtonComponent from '@/components/reusable/ButtonComponent'
import NavCards from '@/components/NavCards'
import BackToTop from '@/components/reusable/BackToTop.vue'

export default {
  name: 'Vertriebsmaterialien-test',
  metaInfo: {
    title: 'Vertriebsmaterialien'
  },
  components: {
    Icon,
    ButtonComponent,
    NavCards,
    BackToTop
  },
  data() {
    return {
      cards: [
        {
          icon:
            '<svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 21a1 1 0 0 1 1-1h27a1 1 0 0 1 1 1v19a1 1 0 0 1-1 1H14.75L5.5 46 5 41H3a1 1 0 0 1-1-1V21Z" stroke="#31A9E0" stroke-width="2" stroke-linejoin="round"/><path d="M17 17V4a1 1 0 0 1 1-1h18m-1 21.486L41.5 28l.5-5h2a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-3.5" stroke="#31A9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M22 9h5M31 9h9M22 13h18" stroke="#F26722" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><rect x="6" y="25" width="15" height="11" rx="2" stroke="#F26722" stroke-width="2" stroke-linejoin="round"/><path d="m21 29 5-2v7l-5-2" stroke="#F26722" stroke-width="2" stroke-linejoin="round"/><circle cx="13.5" cy="30.5" r="2.5" stroke="#F26722" stroke-width="2"/></svg>',
          link: {
            label: 'Öffnen',
            url: { name: 'Webinare' },
            type: 'internal'
          },
          title: 'Webinare',
          text: 'Ein Rückblick auf unsere Webinare'
        },
        {
          icon:
            '<svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 4h26v37H3V4Z" stroke="#31A9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M45 38V13h-4v4m4 21v2a2 2 0 0 1-2 2v0a2 2 0 0 1-2-2v-2m4 0h-4m0 0V21M41 13h4l-2-7.5-2 7.5Z" stroke="#F26722" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="m20.5 44.5 12.266 1.049 3.185-35.86-3.185-.282" stroke="#31A9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M13.553 25.79h3.894M15.5 14v.842m5.303 1.378-.589.595M23 21.58h-.833m-13.334 0H8m2.786-4.764-.59-.595m2.357 8.337a4.225 4.225 0 0 1-1.14-2.156 4.252 4.252 0 0 1 .238-2.433c.315-.77.85-1.427 1.534-1.89a4.136 4.136 0 0 1 4.63 0 4.202 4.202 0 0 1 1.534 1.89c.316.77.398 1.616.238 2.433a4.225 4.225 0 0 1-1.14 2.156l-.457.46a2.841 2.841 0 0 0-.823 2.01v1.289c0 .447-.176.875-.489 1.19a1.658 1.658 0 0 1-2.357 0 1.693 1.693 0 0 1-.488-1.19v-1.29c0-.753-.296-1.477-.823-2.009l-.457-.46Z" stroke="#F26722" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          link: {
            label: 'Öffnen',
            url: { name: 'Cyber-Akademie' },
            type: 'internal'
          },
          title: 'Cyber-Akademie',
          text: 'Meine Weiterbildungsmöglichkeiten (IDD)'
        }
      ]
    }
  },
  computed: {
    isSubPage() {
      return this.$route.matched.length > 1
    }
  },
  mounted: function () {},
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '../scss/variables';
@import '../scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.sales-materials {
  padding-top: 2rem;

  @include media-breakpoint-down(sm) {
    padding-top: 1rem;
  }
}

.go-back {
  font-size: 1.125rem;
  margin-bottom: 3.5rem;

  @include media-breakpoint-down(sm) {
    margin-bottom: 1rem;
  }
}
</style>
