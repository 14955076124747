<template>
    <div id="app" :class="{'create-page': isLoginCreatePage, 'auth-page': isLoginAuthPage}">
        <b-header :user="user"></b-header>
        <LoaderFullScreen v-if="isFullScreenLoader"></LoaderFullScreen>
        <router-view
          id="main_content"
          :class="[$route.meta.slug, isStartPages, {'is-aon': isAon}]"
          :user="user"></router-view>
        <b-nav></b-nav>
        <b-pdf></b-pdf>
        <simplert></simplert>
        <b-footer2 v-if="isLoginCreatePage || isLoginAuthPage"></b-footer2>
        <b-footer v-else-if="!signUpDetected()"></b-footer>
        <modal2 v-if="termOpen && user.isActive" @close="termOpen = false" :closeable="closeable">
            <h3 slot="header">
                Gesch&auml;ftsbedingungen der CyberDirekt f&uuml;r Vermittler
            </h3>
            <template slot="body">
                <AGBContent :isAon="isAon" :modalData="modalData"/>
                <GoToBottom scrollContainer=".modal2-mask" />
            </template>
            <button type="button"
                    @click="accept()"
                    class="btn-style primary"
                    slot="footer">Ich habe die AGB gelesen und akzeptiert
            </button>
        </modal2>
        <ModalEvent id="event-modal" />

        <!-- next modal for this task: CYB-3097 -->
        <ModalBasic v-if="showInactivePopup" id="modal-inactive-info" :value="true" popup-name="inactive-user" :hide-header="true" :unclosable="true">
          <template v-if="createdAfterInactiveTime">
            <div class="modal-top">
              <ButtonComponent btnStyle="linkPrimary" @click.native="cancel" class="cancel-btn">Abmelden <Icon name="cross" class="ml-1"/></ButtonComponent>
            </div>
            <p>Wir freuen uns sehr, dass Sie das CyberDirekt Maklerportal für Ihre Beratung zur Cyberversicherung nutzen möchten.</p>
            <p>
              Damit Sie sich gut zurechtfinden, und unsere Tools optimal nutzen können, möchten wir Ihnen das Portal und seine Funktionen in einem kurzen Kennenlernen vorstellen.
            </p>
            <p>
              Hierzu können Sie direkt <a href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0St30IG-rpZ14hvh3qzPBM3RkH5I6N7BAG7j82QR-uWVm8QTgteP6Le0T8DVlZSQPJnQIjsSXw" target="_blank">HIER</a> einen Termin buchen, oder unter  030-40369529 mit uns einen Termin vereinbaren.
            </p>
            <div class="modal-buttons">
              <ButtonComponent btnStyle="accent" behavior="externalLink" link="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0St30IG-rpZ14hvh3qzPBM3RkH5I6N7BAG7j82QR-uWVm8QTgteP6Le0T8DVlZSQPJnQIjsSXw">
                <Icon name="calendar" class="mr-3"/>Termin vereinbaren
              </ButtonComponent>
              <ButtonComponent btnStyle="accentBorder" behavior="externalLink" link="tel:03040369529"><Icon name="call2" class="mr-3"/>Anrufen
              </ButtonComponent>
            </div>
          </template>
          <template v-else>
            <p>
              Schön, dass Sie wieder da sind! Es ist im Maklerportal viel passiert und wir haben neue Funktionen und Möglichkeiten. Da Sie leider über 1 Jahr nicht mehr das Maklerportal genutzt haben, haben wir dies aktuell pausiert.
            </p>
            <p>
              Zeit für eine Auffrischung!
            </p>
            <p>
              Buchen Sie einfach unter <a href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0St30IG-rpZ14hvh3qzPBM3RkH5I6N7BAG7j82QR-uWVm8QTgteP6Le0T8DVlZSQPJnQIjsSXw" target="_blank">Terminreservierung</a> Ihren Wunschtermin oder rufen Sie uns direkt unter 030-40369529 an.
            </p>
            <p>
              Ihr CyberDirekt Maklervertrieb
            </p>
            <div class="d-flex justify-content-center"><ButtonComponent @click.native="cancel">Abmelden</ButtonComponent></div>
          </template>
        </ModalBasic>
    </div>
</template>

<script>
  import BHeader from '@/components/BHeader'
  import AGBContent from '@/components/AGBContent'
  import BFooter from '@/components/BFooter'
  import BNav from '@/components/BNav'
  import eventBus from '@/components/shared/EventBus.js'
  import MobileDetect from 'mobile-detect'
  import BPdf from '@/components/shared/BPdf'
  import Modal2 from '@/components/Modal2'
  import ModalEvent from '@/components/ModalEvent'
  import ModalBasic from '@/components/reusable/ModalBasic'
  import BFooter2 from '@/components/BFooter-2.vue'
  import ButtonComponent from '@/components/reusable/ButtonComponent'
  import GoToBottom from '@/components/reusable/GoToBottom'
  import LoaderFullScreen from '@/components/reusable/LoaderFullScreen'
  import Icon from '@/components/Icon.vue'
  import {mapState} from 'vuex'
  import { mapGetters } from 'vuex'


  export default {
    name: 'app',
    metaInfo: {
      title: 'Maklerportal',
      titleTemplate: '%s | CyberDirekt'
    },
    components: {
      BHeader,
      BNav,
      BPdf,
      BFooter,
      BFooter2,
      Modal2,
      ModalEvent,
      ModalBasic,
      ButtonComponent,
      AGBContent,
      GoToBottom,
      LoaderFullScreen,
      Icon
    },
    data() {
      return {
        reportUserWhenICOMReady: true,
        termOpen: false,
        closeable: false,
        modalData: {
          isCourtageVisible: true
        }
      }
    },
    computed: {
      ...mapGetters(['isAon','agencyKey', 'isFullScreenLoader']),
      isStartPages() {
        return this.$route.path.indexOf('/versicherung/') > -1
          ? 'start-page'
          : ''
      },
      isLoginPage() {
        return this.$route.path && this.$route.path.indexOf('login') > -1 ? true : false
      },
      isLoginCreatePage() {
        return this.$route.path && this.$route.path.indexOf('create') > -1 ? true : false
      },
      isLoginAuthPage() {
        return this.$route.path && this.$route.path.indexOf('authenticate') > -1 ? true : false
      },
      user() {
        if (this.$store.state.user && typeof window.__insp !== 'undefined') {
          window.__insp.push(['identify', `${this.$store.state.user.lastname}, ${this.$store.state.user.firstname}`]);
        }

        return this.$store.state.user;
      },
      isTermsChecked() {
        return this.$store.getters.isTermsChecked;
      },
      createdAfterInactiveTime() {
        const userCreatedTime = new Date(this.$store.state.user.created)
        const timePoint = this.$store.state.isActiveStatusTimePoint
        return userCreatedTime >= timePoint
      },
      ...mapState(['showInactivePopup'])
    },
    updated() {
      if (!this.isTermsChecked && !this.isLoginRequired()) {
        this.termOpen = true;
      }
    },
    methods: {
      accept() {
        const self = this;

        this.$store
          .dispatch('UPDATE_USER', {
            termsChecked: true,
            termsCheckedOn: Date.now()
          })
          .then(() => {
            self.termOpen = false;
            return this.$store.dispatch('GET_CURRENT_USER');
          })
      },
      signUpDetected() {
        return this.$route.path && this.$route.path.indexOf('/versicherung') > -1
      },
      checkForMobile: function () {
        const md = new MobileDetect(window.navigator.userAgent)

        if (md.mobile()) {
          document.body.classList.add('isMobile')
        } else {
          document.body.classList.add('isDesktop')
        }
      },
      setInterceptors: function () {
        // const parent = this
        this.$http.interceptors.response.use(
          response => response,
          this.handleIntercept
        )
      },
      isLoginRequired: function () {
        const noLoginRoutes = [
          'CreateAccount',
          'Authenticate',
          'Reset',
          'Forgot',
          'Single Sign On',
          'Login'
        ];

        return noLoginRoutes.some(el => el === this.$route.name)
      },
      handleIntercept: function (err) {
        if (!err.response || !err.response.data) {
          return Promise.reject(err)
        }

        if (this.$route.name === 'Forgot') {
          // handle toasted in component, not here
          return Promise.reject(err);
        }

        const errorMsg = err.response.data.error
        // if (!errorMsg.error) {
        //   return Promise.reject(err)
        // }

        const noLoginRequiered = this.isLoginRequired()
        if (noLoginRequiered && errorMsg.message != 'need_2f') {
          let newMessage = (errorMsg.message.indexOf('E-Mail wird bereits verwendet') > -1)
            ? 'Email already exists'
            : errorMsg.message

          window.scrollTo(0, 0)
          this.$toasted.show(newMessage, {
            theme: 'bubble',
            position: 'bottom-center',
            duration: 4000
          });
          return Promise.reject(err);
        }

        return Promise.reject(err);
      },
      cancel() {
        const self = this;

        this.$store.dispatch('LOGOUT')
          .then(() => {
            self.$router.push('/login/authenticate');
          });
        this.$store.commit('STOP_SHOWING_INACTIVE_POPUP')
      },
    },
    mounted: function () {
      this.setInterceptors();

      // eventBus.$on('alertUser', (alert) => {
      //   this.$Simplert.open(alert)
      // });

      eventBus.$on('showTermsModal', () => {
        this.termOpen = true;
        this.closeable = true;
      });

      this.checkForMobile();

      this.$router.beforeEach((to, from, next) => {
        // this is such a weird implementation.
        if (['blau-direkt', 'wika', 'allfinanztest', 'efb', 'impuls', 'sdv'].indexOf(this.$store.state.user.agency) > -1) {
          this.modalData.isCourtageVisible = false;
        }

        next();
      });
    },
    destroyed: function () {
      // Philosophical question: Do we need to unbind the events in App.vue if App.vue is universe?
      eventBus.$off('alertUser');
    }
  }
</script>

<style lang="scss">
    // TODO: Make this shared with the marketing website??
    @import '../scss/variables';
    @import '../scss/mixins';
    @import '../scss/print/print';

    $mediumOverwrite: 760px;

    $breakpoints: (
            small: 0,
            medium: $mediumOverwrite,
            large: 1024px,
            xlarge: 1200px,
            xxlarge: 1440px,
    );
    @import '~bootstrap/scss/mixins';
    @import '../scss/tooltip';

    body {
        background: #fff;
    }

    #app {
      -webkit-overflow-scrolling: lazy;
      font-family: $bodyFontFamily;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      // padding-top: $standardGutter * 2;
      position: relative;
      padding-top: 68px;

      @include media-breakpoint-down(md) {
        padding-top: 68px;
      }

      @include media-breakpoint-down(sm) {
        padding-top: 61px;
      }
    }

    p {
        font-size: 1.3rem;
    }

    a {
        color: $blueTone;
    }

    input {
        box-shadow: none;
        border: 1px solid #ccc;
    }

    .btn-secondary {
        background: $secondary !important;
        color: #fff;
    }

    #global_alert {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .vue-alert {
            opacity: 1 !important;
            // width: 50%;
            align-items: center;
            display: none;
            justify-content: center;
            margin: calc($standardGutter/2) 0;
            p {
                line-height: 1;
                padding: 0;
                margin: 0;
            }
        }
        .vue-alert.alert-success, .vue-alert.alert-danger {
            display: flex;
        }
    }

    #main_content {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        @include media-breakpoint-up(md) {
          padding-top: 3rem;
          padding-bottom: 3rem;
        }

        &.show-notification {
          padding-top: 0;
        }

    }

    .btn-style:not([href]),
    .btn-style:not([tabindex]) {
        @include btn-style-core();
        border-radius: 10px;
        border: 2px solid $orangeTone;
        color: $orangeTone;
    }

    .btn-style:hover,
    .btn-style:focus {
        @include btn-style-core_hover();
        cursor: pointer;
    }

    .btn-style:active {
        @include btn-style-core_active();
    }

    a.btn-style.primary:not([href]),
    .btn-style.primary {
        background: $orangeTone;
        color: #fff;
    }

    .btn-style.loading {
        background-image: url(@/assets/tail-spin.svg);
        background-position: 50%;
        background-size: 28px;
        background-repeat: no-repeat;
        span {
            visibility: hidden;
        }
    }

    .btn-style.primary:active {
        background-color: $hoverColorActive;
    }

    .panels {
        fieldset {
            border-radius: $borderRadius;
            border: 2px solid #ccc;
            margin: auto;
            padding: 1em;
            max-width: 452px;
            legend {
                font-weight: 500;
                font-size: 1.2rem;
                padding: .5rem;
            }
        }
    }

    body.modal-open {
        width: 100%;
        overflow: hidden;
        position: absolute;
    }

    body.isMobile.modal-open {
        position: fixed;
    }

    body.isDesktop {
        .btn-style.primary:hover {
            background-color: $hoverColor;
        }
    }

    #main_content.standard-page {
        padding-top: 0;
        padding-bottom: 0;
        > .container {
            max-width: none;
            padding: 0;
        }
        .page {
            padding-bottom: calc($standardGutter * 2);
            $centerImgWidth: 173px;
            background: #f2f2f2;

            .section-title:first-child {
                padding-bottom: calc($standardGutter / 20);
                h1 {
                    font-size: 2.3rem;
                }
                h1.subhead {
                    font-size: 1.3rem;
                }
            }

            .section-title {
                text-align: center;
                padding-top: calc($standardGutter * 1.5);
                padding-bottom: $standardGutter;

                h3 {
                    font-size: 1.6rem;
                }
            }

            .overview-bucket {
                background: #fff;
                border-radius: $borderRadius;
                overflow: hidden;
                margin-bottom: $standardGutter;

                > div {
                    background: #fff;
                }
                > div:first-child {
                    padding-left: 0;
                    padding-right: 0;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: 50%;
                    min-height: 488px;
                }

                > div:last-child {
                    display: flex;
                    align-items: center;
                    padding: 2.5rem 0;
                }

                ul {
                    $thisDimension: 58px;
                    width: 100%;
                    margin-bottom: 0;
                    // padding: 0;
                    li {

                        h4 {
                            // color: $blueTone;
                        }
                        p {
                            font-size: 1.3em;
                        }
                    }
                }
            }
            // end overview list

            .plate {
                overflow: hidden;
                border-radius: $borderRadius;
                padding: 50px;
                background: #fff;
                margin-bottom: calc($standardGutter * 2);

                h4 {
                    margin-top: $standardGutter;
                    font-size: 1.5em;
                    font-weight: 500;
                }
            }
        }

        #ctas {
            $thisShadow: inset 0 22px 47px -40px rgba(0, 0, 0, 0.9);
            box-shadow: $thisShadow;
            -webkit-box-shadow: $thisShadow;
            -moz-box-shadow: $thisShadow;
            background: #fff;
            position: fixed;
            bottom: 0;
            width: 100%;
            height: 80px;
            margin: 0;
            padding: 0 26px 0 0;
            max-width: none;

        }
    }

    #main_content.start-page {
        padding-top: 19px;

        #steps {
          top: 68px;
        }

        @include media-breakpoint-down(sm) {
          padding-top: 0;
        }
    }

    #main_content.start-page.is-aon {
      #steps {
          top: 64px;
        }
    }

    .btn-primary {
        background-color: $blueTone;
        border-color: $blueTone;
    }

    .tab-controls.cyberdirekt {
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 51px;
        button {
            margin-right: 10px;
            font-size: .9rem;
            width: 169px;
            height: 70px;
            word-wrap: break-word;
        }

        button:last-child {
            margin-right: 0;
        }
    }

    .select-button {

        $buttonDimensions: 34px;
        $innerBtnDim: 22px;
        cursor: pointer;
        text-align: center;

        h5 {
            color: #000;

        }
        > div {
            display: flex;
            align-item: center;
            justify-content: center;
            .s-circle {
                border: 2px solid #5b5b5b;
                background: #fff;
                width: $buttonDimensions;
                height: $buttonDimensions;
                border-radius: calc($buttonDimensions / 2);
                display: flex;
                align-items: center;
                justify-content: center;
                .inner-circle {
                    visibility: hidden;
                    width: $innerBtnDim;
                    height: $innerBtnDim;
                    background: $orangeTone;
                    border-radius: calc($innerBtnDim / 2);
                }
            }
        }
    }

    .select-button.selected {
        h5 {
            color: $orangeTone;
        }
        > div {
            .s-circle {
                border-color: $orangeTone;
                .inner-circle {
                    visibility: visible;
                }
            }
        }
    }

    // Forms

    .fields-bucket {
        display: flex;

        input {
            width: 100%;
            transition: width .3s;
        }
        span.val-check {
            // justify-content: flex-end;
            width: 8%;
            position: relative;
            left: 10px;
            top: -1px;
            text-align: center;
            display: none;
        }
        // align-items: center;
        justify-content: flex-start;
    }

    .fields-bucket.touched {
        input {
            width: 90%;
            transition: width .3s;
        }
        span.val-check {
            display: inline-block;
        }
    }

    input.dirty,
    input:focus.dirty {
        border: 1px solid $greenTone;
    }

    input.is-danger.dirty,
    input:focus.is-danger.dirty {
        border-color: $redTone;
    }

    span.val-check {
        color: $greenTone;
        font-size: 2rem;

        .ion-close-circled {
            color: $redTone;
        }
    }

    .container--content {
        padding-top: 120px;

        @include media-breakpoint-down(xl) {
          padding-top: 68px;
        }

        @include media-breakpoint-down(sm) {
          padding-top: 2rem;
        }
    }

    .secondary-page {
        .page-title {
            // margin-top: 110px;
            margin-bottom: $standardGutter;

            h1 {
                color: $blueTone;
            }

            .back-btn {
                text-align: right;
                a {
                    border: 0;
                }
            }
        }

        .h1 {
          color: $blueTone;
        }

        @include media-breakpoint-down(md) {
            .page-title {
                .back-btn {
                    text-align: left;
                    a {
                        padding-left: 0;
                    }
                }
            }
        }

        &.sales-materials {
          .page-title {
            margin-top: 0;
          }
        }
    }

    .navigation-item {
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 20px;
        min-height: 180px;
        align-items: center;
        cursor: pointer;
        font-family: $bodyFontFamily;

        @include media-breakpoint-down(md) {
            min-height: 221px;
        }
        @include media-breakpoint-up(md) {
            min-height: 240px;
        }

        @include media-breakpoint-up(lg) {
            min-height: 235px;
        }

        &__image {
            justify-self: flex-start;
            margin-top: 20px;
        }

        &__text {
            margin-top: auto;
            justify-self: flex-end;
            font-weight: bold;
        }

        &__description {
            margin-top: auto;
            justify-self: center;
            font-style: italic;
            opacity: 0;
            transition: opacity .27s ease-in;
            text-align: center;
            font-weight: 500;
            width: 100%;
        }

        &--a {
            background: $orangeTone;
            color: #fff;
        }

        &--b {
            background: $darkBlueTone;
            color: #fff;
        }

        &--c {
            background: $blueTone;
            color: #fff;
        }

        &--d {
            background: $greyTone-2;
            color: $darkBlueTone;
        }

        &:hover {
            .navigation-item__description {
                opacity: 1;
            }
        }

        .nabisco {
            position: absolute;
            z-index: 1;
            padding: 8px;
            width: 199px;
            top: 35px;
            right: -49px;
            font-weight: bold;
            background: #f26623;
            color: #fff;
            -webkit-transform: rotate(-45deg);
            text-align: center;
            transform: rotate(45deg);
        }
    }

    .disabled-nav.navigation-item {
        cursor: not-allowed;
        background-color: #b5b5b5;
    }

    .row.normalize-gutter {
        margin-left: 0;
        margin-right: 0;
    }

    .std-pdf-preview {
        text-align: center;
        cursor: pointer;
        margin-bottom: $standardGutter;

        .l-container {
            background-position: 60%;
            border: 1px solid $greyTone;
            position: relative;
            // border-radius: $borderRadius;
            overflow: hidden;
            > div:first-child {
                height: 100%;
                // background-position: center -30px;
                background-size: cover;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .title-area {
                @include absoluteMe();

                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(32, 29, 90, 0.15);

                h4 {
                    // background: $blueTone;
                    display: block;
                    width: 100%;
                    color: #fff;
                    margin-bottom: 0;
                    padding: 8px 0;
                    font-weight: 600;
                    // border-top: 1px solid #ccc;
                }

                &:hover {
                    background: $violetOverlayTone;
                }
            }
        }

        > div:first-child {

        }
    }

    .col-md-6.std-pdf-preview {
        .l-container {
            > div:first-child {
                height: 280px;
            }
        }
    }

    .pdf-std-module {
        margin-bottom: calc($standardGutter * 2);
        .std-pdf-preview {
            > div {
                height: 250px;
            }
        }
        .markt-content {
            text-align: left;

            > div {
                margin-left: 50px;
                // display: flex;
                // align-items: center;
            }
            h4 {
                font-weight: 800;
                a {
                    color: $orangeTone;
                }
            }
            h6, p {
                font-size: 1.1rem;
            }
            small {
                font-weight: 600;
                font-size: .9rem;
            }
            ul {
                padding: 0;
                margin-top: $standardGutter;
                list-style-type: none;
                li {
                    font-size: .9em;
                }
            }

            a {
                font-weight: 600;
            }
        }
        @include media-breakpoint-down(sm) {
            // flex-direction: column-reverse !important;

            .markt-content {
                > div {
                    margin-left: 0;
                }
            }
        }
        @include media-breakpoint-up(lg) {
            .std-pdf-preview {
                > div {
                    height: 220px;
                }
            }
        }
    }

#app.auth-page {
    background: #14192F;

    #main_content.login-wrapper {
        margin-bottom: 3rem;
    }
}


#modal-inactive-info {
  .modal-content {
    padding: 2rem 4rem;

    @include media-breakpoint-down(xs) {
      padding: 2rem 1rem;
    }
  }

  .modal-top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;

    @include media-breakpoint-down(xs) {
      margin-bottom: 1rem;
    }

    .cancel-btn {
      font-size: 1.125rem;
    }
  }

  .modal-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.75rem 2rem;
    flex-wrap: wrap;
    margin-top: 2rem;

    @include media-breakpoint-down(md) {
      justify-content: center;
    }

    .btn {
      min-width: 47%;

      @include media-breakpoint-down(xs) {
        width: 100%;
      }
    }
  }
}
</style>