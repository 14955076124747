<template>
  <div class="secondary-page">
    <div class="container">
      <div class="row page-title">
        <div class="col">
          <h1>Webinare</h1>
        </div>

        <div class="col-12 col-md-2 back-btn">
          <router-link class="btn-style" :to="'/'">&larr;Zurück</router-link>
        </div>
      </div>
      <section class="upcoming-webinars row" v-if="upcomingWebinars.length">
        <h2>Kommende Webinare</h2>
        <Webinar v-for="webinar in upcomingWebinars" :webinarData="webinar.webinar" :key="webinar.webinar.id" class="col-12"/>
      </section>

      <section class="past-webinars row" v-if="pastWebinars.length">
        <h2>Vergangene Webinare</h2>
        <Webinar v-for="webinar in pastWebinars" :webinarData="webinar.webinar" :key="webinar.webinar.id" class="col-12"/>
      </section>
    </div>
    <BackToTop />
  </div>
</template>

<script>
import Webinar from '../components/Webinar.vue'
import { mapGetters } from 'vuex'
import BackToTop from '@/components/reusable/BackToTop.vue'


export default {
  name: 'Webinare',
  metaInfo: {
    title: 'Webinare',
  },
  components: {
    Webinar,
    BackToTop
  },
  computed:{
    ...mapGetters(['webinarList']),
    upcomingWebinars () {
      return this.webinarList ? this.webinarList.upcomingWebinars : []
    },
    pastWebinars () {
      return this.webinarList ? this.webinarList.pastWebinars : []
    }
  },
  data() {
    return {
      videoOptions: {
        autoplay: true,
        controls: true,
        autoplay:false,
        sources: [
          {
            src: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/general/webinars/Kick-off-Webinarreihe-Abgrenzung-der-Cyberversicherung.mp4',
            type: 'video/mp4',
          },
        ],
      },      
    }
  },
  methods: {
    sortByUpdatedAt(array) {
      return array.sort((a,b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables';
@import '../scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.cd-video {
  margin-bottom: 20px;
}

.upcoming-webinars + .past-webinars {
  margin-top: 3rem;
}

h2 {
  font-size: 1.75rem;
  text-align: center;
  color: $primary;
  width: 100%;
  margin-bottom: 1rem;

  @include media-breakpoint-down(sm) {
    margin-bottom: 1.25rem;
  }
}

::v-deep .past-webinars {
  .webinar__link {
    display: none;
  }
}
</style>
