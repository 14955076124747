<template>
  <div class="market-comparison">
    <div class="controls text-center mb-2 d-block d-lg-none">
      <button class="btn btn--text-only btn--text-only--grey btn-block"
              type="button"
              @click="showFilter = true">
        <icon name="filter" color="dark-grey" width="32"></icon>
        Filter
      </button>
    </div>
    <div class="row mb-4">
      <div class="col-12 col-lg-3">
        <h1>Marktvergleich</h1>

        <ButtonComponent
          v-if="showGoDetailedBtn"
          class="icon-link d-print-none market-comparison__go-detailed mb-2"
          btn-style="link-primary"
          :icon="{
            name: 'arrow-full-right',
            position: 'right'
          }"
          behavior="external"
          openNewTab="false"
          @click.native.prevent="compare()"
          >Zum ausführlichen Vergleich</ButtonComponent
        >
        <h2>{{ date }}<br>Berücksichtigte Angaben:</h2>
      </div>
      <div class="col-12 col-lg-9 d-flex align-items-end">
        <h2 class="text-left pl-lg-3">
          {{ inputs.industry.l }}<br>
          Jahresumsatz {{ revenueRange.start | currency(0) }} - {{ revenueRange.end | currency(0) }}
        </h2>
      </div>
      <div class="col-12 mt-2 d-lg-none">
        <ButtonComponent @click.native="showFilter = !showFilter">Filter</ButtonComponent>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-12 col-lg-3" :class="{'show-filter-bg': showFilter}" @click="filterBgClick">
        <div class="market-comparison market-comparison__filter mb-lg-2"
             :class="{'market-comparison__filter--in': showFilter}"
             v-sticky="stickySettings"
             ref="sticky">
          <button class="btn--close float-right d-inline-block d-lg-none"
                  @click="showFilter = false">
            <icon name="cancel" width="30" color="black"></icon>
          </button>
          <div class="clearfix d-block d-lg-none"></div>
          <div class="form-group">
            <label>Sortieren nach</label>
            <select class="form-control" v-model="sortMode">
              <option :value="s.value" v-for="(s, index) in sortModes" :key="index">
                {{ s.label }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Versicherungssumme</label>
            <select class="form-control" v-model="inputs.insuredSum">
              <option :value="s" v-for="(s, index) in insuredSums" :key="index">
                {{ s | currency(0) }}
              </option>
            </select>
            <label v-if="emptyInsuredSum" class="warning-notification-form orange-tone">
              {{ noInsuredSumText }}
            </label>
          </div>
          <div class="form-group">
            <label>Selbstbehalt</label>
            <select class="form-control" v-model="inputs.deductible">
              <option :value="d" v-for="(d, index) in possibleDeductibles" :key="index">
                {{ d | currency(0) }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Zahlweise</label>
            <select class="form-control" v-model="inputs.paymentPeriod">
              <option :value="p.value" v-for="(p, index) in paymentPeriods" :key="index">
                {{ p.label }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Optionale Bausteine</label>
            <select class="form-control" v-model="selectedModulesType">
              <option :value="m.value" v-for="(m, index) in moduleModes" :key="index">
                {{ m.label }}
              </option>
            </select>
          </div>
          <button class="btn btn-secondary btn-block"
                  type="button"
                  v-if="comparison.length > 1"
                  @click="compare()">
            <span v-if="isWWW">Vergleichen {{ comparison.length }}/3</span>
            <span v-else>Vergleichen</span>
          </button>
        </div>
      </div>
      <div class="col-12 col-lg-9">
        <div class="market-comparison__result" v-if="!isLoading && insurers && insurers.length > 0">
          <div class="insurer mb-3" :class="{'insurer--disabled': insurer.disabled}" v-for="(insurer, id) in insurers" :key="id">
            <div class="insurer__head align-items-center d-flex"
                 :class="{'justify-content-between': hasBanner(insurer)|| shouldShowArtusBanner(insurer), 'justify-content-end': !hasBanner(insurer) && !shouldShowArtusBanner(insurer)}">
              <div class="insurer__banner insurer__banner--artus"
                   v-if="shouldShowArtusBanner(insurer)">
                <div class="insurer__banner__text">
                  ARTUS Sonderbedingungen
                </div>
                <div class="insurer__banner__arrow"></div>
              </div>
              <div v-if="hasBanner(insurer)" class="insurer__banner__box">
                <div class="insurer__banner"
                  v-for="(bnr, idx) in getBanner(insurer)" :key="idx"
                  :class="[
                    getBannerClass(insurer),
                    getLabelStyle(bnr.style),
                    getLabelColor(bnr),
                    {'insurer__banner--shifted': idx > 0}
                  ]"
                  v-tooltip.bottom="bnr.long"
                >
                  <div class="insurer__banner__arrow insurer__banner__arrow--start"
                    v-if="idx > 0">
                  </div>
                  <div class="insurer__banner__text">
                    {{ bnr.short }}
                  </div>
                  <div class="insurer__banner__arrow"></div>
                </div>
              </div>
              <div class="custom-control custom-checkbox d-flex"
                   v-if="isComparison(insurer.key)">
                <input type="checkbox"
                       class="custom-control-input"
                       :id="`comparisonCheck-${insurer.key}`"
                       :value="insurer.key"
                       v-model="comparison"
                       :disabled="insurer.disabled">
                <label class="custom-control-label" :for="`comparisonCheck-${insurer.key}`">
                  vergleichen
                </label>
              </div>
            </div>
            <div class="insurer__body d-flex flex-column flex-md-row">
              <div class="insurer__col1 d-flex flex-column justify-content-end">
                <div class="insurer__image" :class="['insurer__image--' + insurer.key]">
                  <img :src="getInsurerLogoPath(insurer)" :alt="insurer.label">
                </div>
                <div class="insurer__details">
                  <button class="btn-block btn--text-only btn--text-only--primary"
                          @click="showDetails(insurer)">
                    Mehr Details
                    <icon name="chevron-down" color="primary"></icon>
                  </button>
                </div>
              </div>
              <div class="insurer__col1">
                <div>
                  <icon name="info" color="dark-grey" width="20"></icon>&nbsp;
                  Eigen- und Drittschäden
                </div>
                <div>
                  <icon name="lecture" color="dark-grey" width="20"></icon>&nbsp;
                  Mitarbeiterschulung
                </div>
                <div>
                  <icon name="phone" color="dark-grey" width="20"></icon>&nbsp;
                  24/7 Hotline im Notfall
                </div>
                <div>
                  <icon name="warning" color="dark-grey" width="20"></icon>
                  &nbsp;
                  <span>{{ getRiskQuestionCountText(insurer) }}</span>
                </div>
              </div>
              <div class="insurer__col2">
                <div class="optional-property d-flex flex-row mb-2 align-items-center"
                     v-for="optionalProperty in optionalProperties" :key="'optionalProperty-' + optionalProperty.key">
                  <div class="optional-property__image mr-1">
                    <div v-if="isAvailable(insurer, optionalProperty.key)">
                      <img class="circle-icon"
                           src="https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/checkout/images/check_green.svg"
                           alt="Versichert">
                    </div>
                    <div class="d-flex"
                         v-else-if="isOptional(insurer, optionalProperty.key)">
                      <input class="optional"
                             type="checkbox"
                             :value="optionalProperty.key"
                             v-model="inputs.selectedModules[insurer.key]"
                             v-on:change="optionalChanged(insurer.key, optionalProperty.key)"
                             :disabled="isOffer"
                             :id="insurer.key + '-' + optionalProperty.key">
                      <label :for="insurer.key + '-' + optionalProperty.key"></label>
                    </div>
                    <div v-else>
                      <img class="circle-icon"
                           src="https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/checkout/images/not_available.svg"
                           alt="Nicht versichert">
                    </div>
                  </div>
                  <div class="optional-property__image">
                    {{ optionalProperty.label }}
                  </div>
                </div>
              </div>
              <div class="insurer__col1 d-flex align-items-end">
                <div class="price text-center text-md-right">
                  <div class="price__amount mb-1" v-tooltip.bottom="getPriceToolTip(insurer)">
                    {{ insurer.prices.gross | currency }}
                    <span v-if="getPriceToolTip(insurer)">*</span>
                  </div>
                  <div class="price__note mb-1">{{ paymentPeriod }}<br>inkl. {{insTaxRate}}% Versicherungssteuer
                  </div>
                  <div class="price__cta">
                    <button type="button"
                            class="btn btn-secondary btn-sm btn-block"
                            :disabled="insurer.disabled"
                            @click="selectInsurer(insurer)">{{ order1 }} {{ order2 }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="market-comparison__result--warning" v-else >
          <div class="d-flex flex-row align-items-center">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="mx-2" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
              </svg>
            </div>
            <span v-html="noInsurersText"></span>
          </div>
        </div>
      </div>
    </div>
    <input type="submit" id="step_submit">
    <div class="loading-overlay" v-if="isLoading" :class="{'loading-overlay--in': isLoading}">
      <LoaderComponent></LoaderComponent>
    </div>
    <div class="d-flex d-md-none comparison-button">
      <button class="btn btn-primary btn-lg text-white"
              type="button"
              @click="compare()"
              :disabled="comparison.length <= 1">
        Tarife vergleichen <span v-if="isWWW">{{ comparison.length }}/3</span>
      </button>
    </div>
    <contact-tool v-if="isWWW" :meta="{'link': shareLink}">
    </contact-tool>
    <!-- TODO: modal in single component -->
    <modal class="offer-modal" :closeable="true" v-if="showRegModal" @close="showRegModal = false">
      <h3 slot="header" v-if="!showAffiliateSuccess">
        <span v-if="isAffiliate">Angebot anfordern</span>
        <span v-else>Erstellen Sie ein Angebot</span>
      </h3>
      <div slot="body" v-if="showAffiliateSuccess">
        <div class="col-12">
          <h2 class="text-center">Vielen Dank!</h2>
          <p class="text-center">Sie erhalten umgehend per E-Mail ein unverbindliches Angebot, das Ihren
            individuellen Anforderungen entspricht.</p>
        </div>
        <div class="col-12 col-sm-6 offset-sm-3 col-md-4 offset-md-4 col-lg-2 offset-lg-5">
          <img src="https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/checkout/images/success_icon.svg">
        </div>
      </div>
      <div slot="body" v-if="!showAffiliateSuccess">
        <table class="table" v-if="!isAffiliate">
          <thead>
          <tr>
            <th>Versicherer im Vergleich</th>
            <th>Als Empfehlung kennzeichnen</th>
            <th>Entfernen</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="insurerKey in offerSelection" :key="insurerKey">
            <td>{{ getInsurerName(insurerKey) }}</td>
            <td class="text-center">
              <div class="custom-control custom-radio">
                <input class="custom-control-input"
                       type="radio"
                       :id="`radio-${insurerKey}`"
                       v-model="inputs.insurer"
                       :value="insurerKey"
                       name="selectedInsurer">
                <label class="custom-control-label" :for="`radio-${insurerKey}`"></label>
              </div>
            </td>
            <td>
              <button class="btn btn-icon" type="button" @click="removeSelectedInsurer(insurerKey)">
                <icon name="delete" color="dark-grey"></icon>
              </button>
            </td>
          </tr>
          <tr>
            <td>keine Empfehlung</td>
            <td class="text-center" colspan="2">
              <div class="custom-control custom-radio">
                <input class="custom-control-input"
                       type="radio"
                       id="radio-no-recommendation"
                       v-model="inputs.insurer"
                       value="no-recommendation"
                       name="selectedInsurer">
                <label class="custom-control-label" for="radio-no-recommendation"></label>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="input-group mb-5" v-if="!isAffiliate">
          <select class="form-control"
                  name="additionalInsurer"
                  id="additionalInsurer"
                  :disabled="offerSelection.length >= 4"
                  v-model="additionalInsurer">
            <option :value="insurer.key" v-for="insurer in additionalInsurers" :key="insurer.key">{{ insurer.label }}</option>
          </select>
          <div class="input-group-append">
            <button class="btn btn--secondary"
                    type="button"
                    @click="addAdditionalInsurer()"
                    :disabled="offerSelection.length >= 4 || !additionalInsurer">
              Weiteren Versicherer hinzufügen (max. 4)
            </button>
          </div>
        </div>
        <div class="form-group" :class="getFieldClass('company')">
          <label for="company">Firma*</label>
          <input class="form-control"
                 type="text"
                 id="company"
                 name="company"
                 v-model="offer.company"
                 v-validate="'required'">
          <small class="error-message">Geben Sie mindestens den Firmen- oder Nachnamen ein.</small>
        </div>
        <div class="form-group" :class="getFieldClass('salutation')">
          <label for="salutation">Anrede</label>
          <select class="form-control"
                  name="salutation"
                  id="salutation"
                  v-model="offer.salutation">
            <option selected disabled hidden value="">Wählen</option>
            <option value="Frau">Frau</option>
            <option value="Herr">Herr</option>
          </select>
        </div>
        <div class="form-group" :class="getFieldClass('firstname')">
          <label for="firstname">Vorname</label>
          <input class="form-control"
                 type="text"
                 id="firstname"
                 name="firstname"
                 v-model="offer.firstname">
        </div>
        <div class="form-group" :class="getFieldClass('lastname')">
          <label for="lastname">Nachname *</label>
          <input class="form-control"
                 type="text"
                 id="lastname"
                 name="lastname"
                 v-model="offer.lastname"
                 v-validate="'required'">
          <small class="error-message">Geben Sie mindestens den Firmen- oder Nachnamen ein.</small>
        </div>
        <div class="form-group" :class="getFieldClass('email')" v-if="isAffiliate">
          <label for="email">E-Mail *</label>
          <input class="form-control"
                 type="text"
                 id="email"
                 name="email"
                 v-model="offer.email"
                 v-validate="'required|email'">
        </div>
        <!-- <div class="form-group" :class="getFieldClass('phone')" v-if="isAffiliate">
        <div class="form-group" :class="getFieldClass('phone')" v-if="isAffiliate">
          <label for="phone">Telefon *</label>
          <phone-resolver class="phone-resolver-container"
            id="phone"
            name="phone"
            v-validate="this.affiliateBroker && this.affiliateBroker.agency === 'synaxon' ? 'required|min:4' : 'min:4'"
            v-model="offer.phone">
          </phone-resolver>
        </div> -->
        <PhoneForm
          class="form-relative"
          name="Mobiltelefon"
          v-validate="this.affiliateBroker && this.affiliateBroker.agency === 'synaxon' ? 'required|min:4' : 'min:4'"
          :required="this.affiliateBroker && this.affiliateBroker.agency === 'synaxon' ? true : false"
          v-model="offer.phone"
          @numberChange="updatePhoneNumber"
        />
        <div class="custom-control custom-checkbox"
             :class="{'is-danger': submitted && !offer.dataPrivacyCheck}"
             v-if="isAffiliate">
          <input type="checkbox"
                 class="custom-control-input"
                 id="dataPrivacyCheck"
                 name="dataPrivacyCheck"
                 v-model="offer.dataPrivacyCheck">
          <label class="custom-control-label text-muted check-label legal-note" for="dataPrivacyCheck">
            Ich bin mit den
            <a href="https://www.cyberdirekt.de/datenschutzhinweise"
               target="_blank">Datenschutzhinweisen von CyberDirekt</a>
            <template v-if="this.affiliateBroker && this.affiliateBroker.agency === 'synaxon'">
               und der
              <a href="https://pma-assekuranzmakler.de/datenschutzerklaerung/"
                target="_blank">Assekuranzmakler GmbH</a>
            </template>
             einverstanden.
          </label>
        </div>
        <div class="custom-control custom-checkbox"
             :class="{'is-danger': submitted && !offer.contactBrokerCheck}"
             v-if="isAffiliate">
          <input type="checkbox"
                 class="custom-control-input"
                 id="contactBrokerCheck"
                 name="contactBrokerCheck"
                 v-model="offer.contactBrokerCheck">
          <label class="custom-control-label text-muted check-label legal-note" for="contactBrokerCheck">
            Ich möchte für eine Beratung von {{ affiliateBroker.firstname }} {{ affiliateBroker.lastname }}
            kontaktiert werden.
          </label>
        </div>
        <div class="form-group" :class="getFieldClass('salutationOverwrite')" v-if="!isAffiliate">
          <label for="salutation">Anrede überschreiben</label>
          <input class="form-control"
                 type="text"
                 id="salutationOverwrite"
                 name="salutationOverwrite"
                 v-model="offer.salutationOverwrite">
          <small>Leer lassen für Standardanrede</small>
        </div>
        <div class="form-group" v-if="!isAffiliate">
          <label>Angezeigte Anrede</label>
          <input class="form-control"
                 type="text"
                 readonly
                 :value="getSalutation()">
        </div>
        <div class="form-group" v-if="!isAffiliate">
          <label for="reason">Wahl des Versicherers</label>
          <textarea class="form-control"
                    name="reason"
                    id="reason"
                    rows="5"
                    v-model="offer.reason"></textarea>
        </div>
        <div class="form-group" v-if="!isAffiliate">
          <label for="comment">Kommentar</label>
          <textarea class="form-control"
                    name="comment"
                    id="comment"
                    rows="5"
                    v-model="offer.comment"></textarea>
        </div>
        <div class="form-group" v-if="!isAffiliate">
          <label for="description">Interne Notiz
            <small>(Kann nicht vom Kunden eingesehen werden)</small>
          </label>
          <textarea class="form-control"
                    name="description"
                    id="description"
                    rows="5"
                    v-model="offer.description"></textarea>
        </div>
      </div>
      <div slot="footer" v-if="!showAffiliateSuccess" class="footer-btns--produktauswahl">
        <button class="btn btn--default mr-1"
                type="button"
                @click="showRegModal = false"
                slot="footer">Abbrechen
        </button>
        <button class="btn btn-secondary"
                type="button"
                :disabled="errors.any()"
                @click="saveOffer"
                slot="footer">
          <span v-if="isAffiliate">Angebot anfordern</span>
          <span v-else>Angebot speichern</span>
        </button>
      </div>
    </modal>
    <div class="loading-overlay" v-if="isLoadingOverlay" :class="{'loading-overlay--in': isLoadingOverlay}">
      <LoaderComponent></LoaderComponent>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import eventBus from '@/components/shared/EventBus.js'
import Icon from '../Icon.vue';
import sticky from '../sticky';
import moment from 'moment';
import ConditionCheck from '../ConditionCheck';
import jwt from 'jsonwebtoken';
import Clipboard from '../Clipboard';
import {jwtDecode} from 'jwt-decode'; // for >18 node projects
import ContactTool from './ContactTool';
import store from '@/store/index';
import Modal from '../Modal';
import StringHelper from '../StringHelper';
import {get as getValue} from 'lodash';
import PhoneForm from './shared/PhoneForm'
import ButtonComponent from './calculator-v2.0/ui/ButtonComponent.vue'
import LoaderComponent from './shared/LoaderComponent.vue';
import isStepAllowed from '../mixins/isStepAllowed.js'
import bannersLogic from '../mixins/banners.js'
import multiModules from '../mixins/multiModules.js'
import agencyKey from '../mixins/agencyKey.js'
import defaultSelection from '../mixins/defaultSelection.js'

moment.locale('de');

const jwtDecodeFunc = jwtDecode
const ENCODE_SECRET = 'Ewr8BVlLbYtBIgSV1JSyHDSxo2ViC63EXMA376EzktRB7XrVUZ1bYKXPIv9IqhnLIiTLmS4PBPIkNzMyBLzZBgDw9h27BDEcmJKFNWlW29mXcvnx2bVPMm41q07RZE3X8ncetKTECna0';

const REVENUE_RANGES = [
  0, 100000, 150000, 250000, 500000, 750000, 1000000, 1500000, 2000000, 2500000, 3000000, 4000000,
  5000000, 7500000, 10000000, 10000000, 12500000, 15000000, 17500000, 20000000, 22500000, 25000000,
  27500000, 30000000, 32500000, 35000000, 37500000, 40000000, 42500000, 45000000, 47500000, 50000000,
  50000000, 55000000, 60000000, 65000000, 70000000, 75000000, 80000000, 85000000, 90000000, 95000000,
  100000000
];

export default {
  name: 'Produktauswahl',
  components: {
    Icon,
    ContactTool,
    Modal,
    PhoneForm,
    ButtonComponent,
    LoaderComponent
  },
  directives: {
    sticky
  },
  head() {
    return {
      meta: [
        {
          p: 'og:image',
          c: 'https://www.cyberdirekt.de/static/brand-assets/web/risikoaufklaerung_web.jpg'
        }
      ]
    }
  },
  data() {
    return {
      submitted: false,
      showAffiliateSuccess: false,
      offer: {
        company: '',
        firstname: '',
        lastname: '',
        salutation: 'Herr',
        salutationOverwrite: '',
        reason: StringHelper.get(this.$store, 'OFFER_REASON_NO_RECOMMENDATION', true),
        comment: StringHelper.get(this.$store, 'OFFER_COMMENT_NO_RECOMMENDATION', true),
        description: '',
        phone:''
      },
      showRegModal: false,
      comparison: [],
      optionalProperties: [
        {
          key: 'erpressung',
          label: 'Erpressung'
        },
        {
          key: 'einschluss-cloud-ausfall',
          label: 'Cloud-Ausfall'
        },
        {
          key: 'einschluss-diebstahl',
          label: 'Cyber-Diebstahl'
        },
        {
          key: 'betriebsunterbrechung-nach-zeitlicher-wartezeit',
          label: 'Betr.-Unterbrechung'
        }
      ],
      isLoading: false,
      isLoadingOverlay: false,
      showFilter: false,
      paymentPeriods: [
        {
          value: 'yearly',
          label: 'Jährlich'
        },
        {
          value: 'halfyearly',
          label: 'Halbjährlich'
        },
        {
          value: 'quarterly',
          label: 'Vierteljährlich'
        },
        {
          value: 'monthly',
          label: 'Monatlich'
        }
      ],
      selectedModulesType: this.$store.state.checkout.application.selectedModulesType,
      moduleModes: [
        {
          value: 'default',
          label: 'Produktstandard'
        },
        {
          value: 'all',
          label: 'Alle angewählt'
        },
        {
          value: 'none',
          label: 'Alle abgewählt'
        }
      ],
      sortMode: 'price',
      sortModes: [
        {
          value: 'price',
          label: 'Niedrigster Preis'
        },
        {
          value: 'recommendation',
          label: 'Beliebt bei unseren Kunden'
        }
      ],
      noInsurersText: StringHelper.get(this.$store, 'STEP_2_NO_INSURERS_TEXT', false),
      noInsuredSumText: StringHelper.get(this.$store, 'STEP_2_NO_DEDUCTIBLE', false)
    };
  },
  watch: {
    selectedModulesType() {
      this.selectModules(this.selectedModulesType);
    },
    'inputs.insuredSum'(a, b) {
      if (a !== b) {
        this.loadPrice();
      }
    },
    'inputs.deductible'(a, b) {
      if (a !== b) {
        this.loadPrice();
      }
    },
    'inputs.paymentPeriod'(a, b) {
      if (a !== b) {
        this.loadPrice();
      }
    },
    'inputs.selectedModules': {
      handler(a, b) {
     //   if (a !== b) {
          this.loadPrice();
      //  }
      },
      deep: true
    },
    insurers(newValue) {
      if (this.isLoading) return

      if (newValue.length === 0) {
        eventBus.$emit('alertUser', {
          type: 'info',
          title: null,
          message: this.noInsurersText,
          customCloseBtnText: 'OK',
          customCloseBtnClass: 'btn btn-primary'
        })
      } else {

      }
    }
  },
  computed: {
    ...mapGetters([
      'style',
      'isBroker',
      'isWWW',
      'affiliateBroker',
      'sharablePayload',
      'emptyInsuredSum',
      'insurersWithMultiModules'
    ]),
    // nameCheck() {
    //   return !this.offer.lastname && !this.offer.company ? 'required' : '';
    // },
    showGoDetailedBtn() {
      const affiliateCondition = !this.isAffiliate || (this.agencyKeyValue === 'hornetsecurity')
      return !this.isWWW && !this.isOffer && affiliateCondition
    },
    isAffiliate() {
      return this.style === 'affiliate';
    },
    order1() {
      if (this.style === 'affiliate') {
        return 'Angebot';
      }

      return 'Jetzt';
    },
    order2() {
      if (this.style === 'affiliate') {
        return 'anfordern';
      } else if (this.isBroker || this.isWWW) {
        return 'beantragen';
      }

      return 'abschließen';
    },
    shareLink() {
      const encoded = this._encodeSharedLink(this.sharablePayload);
      let CLIENT_BASE = process.env.CLIENT_BASE || process.env.VUE_APP_CLIENT_BASE;
      CLIENT_BASE = CLIENT_BASE.replace(/\/?$/, '/')
      return `${CLIENT_BASE}versicherung/2/produktauswahl?shared=${encoded}`;
    },
    insuredSums() {
      const possibleInsuredSums = this.$store.getters.possibleInsuredSums(this.insurers);
      const fallback = this.$store.getters.possibleInsuredSums2;
      return possibleInsuredSums.length > 0 ? possibleInsuredSums : fallback;
    },
    comparisonMax() {
      return (!this.isBroker || this.style === 'affiliate') ? 3 : 100;
    },
    date() {
      return moment()
        .format('DD. MMMM YYYY');
    },
    revenueRange() {
      const revenueIndex = Math.max(0, REVENUE_RANGES.indexOf(this.inputs.netTurnOver));

      return {
        start: REVENUE_RANGES[revenueIndex - 1],
        end: this.inputs.netTurnOver
      };
    },
    possibleDeductibles() {
      const calculated = this.$store.getters.possibleDeductibles();
      const simple = this.$store.getters.possibleDeductibles2;
      return calculated.length > 0 ? calculated : simple;
    },
    possibleDeductibles2() {
      return this.$store.getters.possibleDeductibles2;
    },
    paymentPeriod() {
      const paymentPeriod = this.paymentPeriods.find(pp => pp.value === this.inputs.paymentPeriod);
      return paymentPeriod.label;
    },
    insurerPrices() {
      return this.$store.state.checkout.application.prices;
    },
    inputs() {
      return this.$store.state.checkout.application.inputs;
    },
    insurers() {
      return this.sortMode === 'price' ?
        this.$store.getters.filteredInsurers.sort(this._sortByPrice) :
        this.$store.getters.filteredInsurers.sort(this._sortByRecommendation);
    },
    insuranceProperties() {
      return this.$store.getters.filteredInsuranceProperties;
    },
    stickySettings() {
      // no sticky for IE11
      const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
      let offset = 0;

      if (this.$isMobile) {
        return;
      }

      if (this.isOffer) {
        offset = 0;
      } else if (this.isBroker && this.style === 'affiliate') {
        offset = 126;
      } else {
        offset = 140;
      }

      return {
        zIndex: 795,
        stickyTop: offset,
        isIE11
      };
    },
    isOffer() {
      return this.mode === 'offer';
    },
    computedMode() {
      return this.mode || this.$store.state.checkout.application.mode
    }
  },
  props: ['step', 'mode'],
  destroyed: function () {
    eventBus.$off('submitStep');
  },
  mounted: function () {
    const {shared} = this.$route.query;
    const productStep = this.$store.state.checkout.application.steps.find(step => step.slug === 'produktauswahl');

    eventBus.$emit('stepChangeCompleted');

    eventBus.$on('submitStep', () => {
      this.$store.dispatch('UPDATE_INPUT_VALUES', this.inputs);
      document.getElementById('step_submit')
        .click();
    });

    // set default values
    let values = {};

    if (this.inputs.insuredSum) {
      values.insuredSum = this.inputs.insuredSum;
    } else if (this.inputs.netTurnOver < 500000 && this.insuredSums.indexOf(100000) > -1) {
      values.insuredSum = 100000;
    } else {
      if(!this.insuredSums[0]) { console.error('insured sums missing a sum') };
      if(this.inputs.netTurnOver > 10000000) {
        values.insuredSum = this.insuredSums[0];
      } else {
        values.insuredSum = this.insuredSums[1];
      }
    }

    if (shared) {
      try {
        const decoded = jwtDecodeFunc(shared);
        if (productStep) {
          this.$store.commit('SET_ACTIVE_STEP', productStep);
        }
        this.$store.dispatch('UPDATE_INPUT_VALUES', decoded);
        this.$store.commit('SET_MODULES_CHANGED');
      } catch (e) {
        console.log('ERROR AT JWT PART', e);
      }
    } else if (this.isOffer) {
      const {selectedModules} = this.$store.state.contract.contract;
      values.selectedModules = selectedModules;
    } else if (!this.$store.state.checkout.application.modulesChanged) {
      // preselect properties with state 'optional-preselected'
      this.selectModules('default');
    } else {
       this.$store.dispatch('UPDATE_INPUT_VALUES', values);
    }

    if (this.insuredSums.indexOf(this.inputs.insuredSum) === -1) {
      if(!this.insuredSums[0]) { console.error('insured sums missing a default sum, couldn\'t find a sum') };
      if(this.inputs.netTurnOver > 10000000) {
        this.$store.commit('UPDATE_INPUT_VALUES', {insuredSum: this.insuredSums[0] || this.insuredSums[1]}); // if not found
      } else {
        this.$store.commit('UPDATE_INPUT_VALUES', {insuredSum: this.insuredSums[1] || this.insuredSums[0]}); // if not found
      }
    }

    if (this.possibleDeductibles.indexOf(this.inputs.deductible) === -1) {
      this.$store.dispatch('UPDATE_INPUT_VALUES', {deductible: this.possibleDeductibles[0]});
    }

    // possible fix for no insurers shown when there could be some
    if (this.insurers.length === 0 && this.computedMode !== 'www') {
      this.$store.dispatch('UPDATE_INPUT_VALUES', {deductible: this.possibleDeductibles2[0]});
    }

    if (this.comparisonMax > 3) {
      this.comparison = this.insurers.map((insurer) => insurer.key);
    }

    // switch all submodules to be selected for affiliate function
    if (this.isAffiliate || this.isWWW) {
      this.selectedModulesType = 'all';
    }
  },
  beforeRouteEnter(to, from, next) {
    const storeInstance = window && window.$nuxt ? window.$nuxt.$store : store;
    if (storeInstance.state.checkout.application.mode !== 'www') {
      eventBus.$emit('isLoading', true);
      storeInstance
        .dispatch('CALCULATE_CYBER_PRICES')
        .then(() => {
          eventBus.$emit('isLoading', false);
          next();
        });
    } else {
      next()
    }
  },
  beforeRouteUpdate(to, from, next) {
    const storeInstance = window && window.$nuxt ? window.$nuxt.$store : store;
    eventBus.$emit('isLoading', true);
    storeInstance
      .dispatch('CALCULATE_CYBER_PRICES')
      .then(() => {
        eventBus.$emit('isLoading', false);
        next();
      });
  },
  methods: {
    filterBgClick (event) {
      if (this.showFilter && Object.values(event.target.classList).includes('show-filter-bg')) {
        this.showFilter = false
      }
    },
    updatePhoneNumber (res) {
      this.offer.phone = res.number;
    },
    shouldShowArtusBanner(insurer) {
      const isArtus = this.$store.state.user &&
        this.$store.state.user.brokerAgency &&
        this.$store.state.user.brokerAgency.key &&
        this.$store.state.user.brokerAgency.key.includes('artus');
      const offerKey = getValue(this.$store.state, 'offer.offer.key');
      const isArtusCampaign = offerKey && offerKey.indexOf('artus') > -1;

      return (isArtus || isArtusCampaign) && ['hiscox'].indexOf(insurer.key) > -1;
    },
    saveOffer() {
      if (((this.$store.state.user && this.$store.state.user.email === 'info@auxmed.de' && this.isBroker) || (this.affiliateBroker && this.affiliateBroker.agency === 'auxmed')) &&
        this.inputs.industry.w === '86230' &&
        this.inputs.insurer === 'victor-gesundheitswesen-heilwesen') {
        this.offer.campaign = 'victor-fvdz';
      }

      const action = this.isAffiliate ? 'INSERT_AFFILIATE_OFFER' : 'INSERT_OFFER';

      if (this.inputs.insurer) {
        const selectedInsurer = this.insurers.find(i => i.key === this.inputs.insurer);

        if (selectedInsurer) {
          this.offer.net = selectedInsurer.prices.net;
          this.offer.gross = selectedInsurer.prices.gross;
        }
      }

      this.offer.selection = this.$store.state.checkout.application.offer.selectedInsurer;
      this.submitted = true;

      if (this.isAffiliate && (!this.offer.dataPrivacyCheck || !this.offer.contactBrokerCheck)) {
        return;
      }

      this.$validator
        .validateAll()
        .then((result) => {
          if (result) {
            this.isLoadingOverlay = true
            this.$store.commit("RESET_OFFER");
            this.$store
              .dispatch(action, this.offer)
              .then((response) => {
                if (this.isAffiliate) {
                  this.showAffiliateSuccess = true;
                } else {
                  if (this.$analytics) {
                    this.$analytics.track(this.$route.path, {
                      label: 'offerCreated',
                      value: response.data.id
                    });
                  }

                  this.showRegModal = false;
                  this.$router.push({
                    name: 'AngeboteDetail',
                    params: {offerId: response.data.id}
                  });
                }
                this.isLoadingOverlay = false
              })
              .catch(() => {
                return eventBus.$emit('alertUser', {
                  type: 'error',
                  title: 'Es ist ein Fehler aufgetreten. Das Angebot konnte nicht gespeichert werden.',
                  message: '',
                  customCloseBtnText: 'OK',
                  customCloseBtnClass: 'btn btn-primary '
                });
              });
          }
        });
    },
    getFieldClass(fieldName) {
      if (!this.fields[fieldName]) {
        return;
      }

      return {
        'is-danger': this.errors.has(fieldName),
        'touched': this.fields[fieldName].dirty
      };
    },
    loadPrice() {
      this.isLoading = true;
      this.defaultSelection();
      this.$store
        .dispatch('CALCULATE_CYBER_PRICES')
        .then(() => {
          eventBus.$emit('isLoading', false);
          this.isLoading = false;
          // check selection and insurers
          this.comparison = this.comparison.filter((selectedInsurerKey) => {
            return this.insurers.find((insurer) => insurer.key === selectedInsurerKey);
          });
        });
    },
    selectModules(type = 'default') {
      switch (type) {
        case 'default':
          this.$store.commit('DEFAULT_SELECT_MODULES');
          break;
        case 'none':
          this.$store.commit('DESELECT_ALL_MODULE');
          break;
        case 'all':
          this.$store.commit('SELECT_ALL_MODULES');
          break;
      }
    },
    submitForm: function () {
      if (!this.inputs.insurer) {
        return eventBus.$emit('alertUser', {
          type: 'error',
          title: 'Bitte wählen Sie ein Produkt aus.',
          message: '',
          customCloseBtnText: 'OK'
        });
      }

      this.step.completed = true;
      return eventBus.$emit('changeStepForward', this.step);
    },
    getInsurerLogoPath(insurer) {
      return insurer.logoUrl
    },
    getOptionalProperties(insurer) {
      let optionalProperties

      try {
        optionalProperties = this.insuranceProperties.filter((property) => {
          if (insurer.properties[property.key]) {
            return ['optional', 'optional-preselected'].indexOf(insurer.properties[property.key].state) > -1;
          }
        }, this);
      } catch(err) {
        console.error('_setUpGroups insuranceProperties.filter error:', err)
      }

      return optionalProperties;
    },
    isAvailable(insurer, key) {
      if (insurer.properties[key]) {
        return insurer.properties[key].state === 'available' && !insurer.properties[key].disabled;
      } else {
        return false;
      }
    },
    isOptional(insurer, key) {
      if (insurer.properties[key]) {
        return (insurer.properties[key].state === 'optional' || insurer.properties[key].state === 'optional-preselected') && !insurer.properties[key].disabled;
      } else {
        return false;
      }
    },
    optionalChanged(insurer, propertyKey) {
      let groupModuleName = '';
      const multiModuleMap = this.insurersWithMultiModules;

      // check if this is a submodule?
      if (multiModuleMap[insurer]) {
        groupModuleName = this.findMatchingModel(this.insurerPrices[insurer], multiModuleMap[insurer], propertyKey)


        if (groupModuleName) {
          if (this.inputs.selectedModules[insurer].indexOf(propertyKey) === -1) {
            // turn all group modules off
            this.insurerPrices[insurer].modules[groupModuleName].modules.forEach((subModule) => {
              this.$store.commit('DESELECT_MODULE', {
                insurer: insurer,
                module: subModule
              });
            });
          } else {
            // turn all group modules on
            this.insurerPrices[insurer].modules[groupModuleName].modules.forEach((subModule) => {
              if (this.inputs.selectedModules[insurer].indexOf(subModule) === -1) {
                this.$store.commit('SELECT_MODULE', {
                  insurer: insurer,
                  module: subModule
                });
              }
            });
          }
        }
      }

      this.$store.commit('SET_MODULES_CHANGED');
    },
    compare() {
      this.$router.push({
        name: 'produktvergleich',
        query: {insurer: this.comparison}
      });
    },
    showDetails(insurer) {
      this.$router.push({
        name: 'produktvergleich',
        query: {insurer: insurer.key}
      });
    },
    selectInsurer(insurer) {
      this.$store.dispatch('UPDATE_INPUT_VALUES', {insurer: insurer.key});
      this.$store.commit("RESET_OFFER");
      this.$store.commit('UPDATE_OFFER_SELECTION', [insurer.key]);

      if (this.isAffiliate) {
        this.showRegModal = true;
      } else {
        this.step.completed = true;
        return eventBus.$emit('changeStepForward', this.step);
      }
    },
    getRiskQuestionCountText(insurer) {
      const count = this.$store.getters.filteredQuestions(insurer.key).length;

      return count === 1 ? count + ' Risikofrage' : count + ' Risikofragen'
    },
    getLabelStyle(data) {
      if(data != null && this.affiliateBroker && this.affiliateBroker.agency == data) {
        return `insurer__label--${data}`;
      }
    },
    getLabelColor(banner) {
      if (!banner.color) return
      return `insurer__banner--${banner.color}`
    },
    getPriceToolTip(insurer) {
      let tooltipTexts = [];

      if (insurer.tooltips) {
        insurer.tooltips.forEach(tooltip => {
          if (ConditionCheck.check(this.inputs, tooltip.condition)) {
            tooltipTexts.push(tooltip.text);
          }
        }, this);
      }

      return tooltipTexts.length ? tooltipTexts.join(', ') : undefined;
    },
    _encodeSharedLink(data) {
      return jwt.sign(data, ENCODE_SECRET);
    },
    _sortByPrice(a, b) {
      return a.prices.gross - b.prices.gross;
    },
    _sortByRecommendation(a, b) {
      const RECOMMENDATION_ORDER = Object.keys(this.insurerPrices).sort();

      return RECOMMENDATION_ORDER.indexOf(a.key) - RECOMMENDATION_ORDER.indexOf(b.key);
    },
     isComparison (key) {
      return (this.comparison.length < this.comparisonMax || this.comparison.indexOf(key) > -1) && this.insurers.length > 1
    }
  },
  mixins: [isStepAllowed, multiModules, agencyKey, bannersLogic, defaultSelection]
};
</script>
<style lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

body {
  &.loading {
    overflow: hidden;
  }

  @include media-breakpoint-only(xs) {
    scroll-snap-type: y proximity;
    overflow-y: scroll;
    scroll-padding-top: 71px;
  }
}
</style>

<style lang="scss" scoped>
@import '../scss/variables';
@import '../scss/mixins';
@import '../scss/tooltip';
@import '../scss/collapse';
@import '../scss/form';
@import '../scss/button';
@import '../scss/shared';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.phone-resolver-container {
  border: none;
  padding-top: 0;
  padding-left: 0;
}

h1 {
  color: $darkBlueTone;
  font-size: 1.3rem;
}

h2 {
  color: $placeHolderColor !important;
  font-size: 1rem !important;
  margin-bottom: 0 !important;
}

.show-filter-bg {
  display: block;
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  z-index: 900;
}

.market-comparison {
  &__filter {
    padding: 16px;
    background: #fff;
    border: 1px solid $greyTone;
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }

    &--in {
      display: block;
      position: fixed !important;
      top: 50% !important;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 1rem;
      max-width: 600px;
      max-height: fit-content;

      @include media-breakpoint-down(xs) {
        margin: 0;
        width: 90%;
      }
    }
  }

  &__result {
    padding: 0 16px;
  }

  &__go-detailed {
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }
}

.insurer {
  border: 2px solid $greyTone;
  font-size: 0.9rem;
  overflow: hidden;
  position: relative;

  &__label--vodafone {
    .insurer__banner__text {
      background: #e60000;
    }

    .insurer__banner__arrow {
      border-color: transparent transparent transparent  #e60000 ;
    }

    .insurer__banner__arrow__start {
      border-color: transparent transparent #e60000 transparent;
    }

  }

  @include media-breakpoint-only(xs) {
    scroll-snap-align: start;
  }

  &__banner {
    overflow: hidden;
    display: flex;
    flex-direction: row;

    &--shifted {
      margin-left: -10px
    }

    &__text {
      padding: 4px 12px;
      height: 25px;
      color: #fff;
      font-weight: bold;
      text-align: center;
      background: $primary;
    }

    &__arrow {
      border-color: transparent transparent transparent $primary;
      border-style: solid;
      border-width: 0 0 25px 15px;
      width: 0;
      height: 0;
    }

    &__box {
      display: flex;
      flex-direction: row;
    }

    &__arrow--start {
      margin-left: 0.1em;
      transform: scale(-1);
    }

    &--warning {
      .insurer__banner__text {
        background: $redTone;
      }

      .insurer__banner__arrow {
        border-color: transparent transparent transparent $redTone;
      }
    }

    &--green {
      .insurer__banner__text {
        background: $greenTone;
      }

      .insurer__banner__arrow {
        border-color: transparent transparent transparent $greenTone;
      }
    }

    &--orange {
      .insurer__banner__text {
        background: $orangeTone;
      }

      .insurer__banner__arrow {
        border-color: transparent transparent transparent $orangeTone;
      }
    }

    &--red {
      .insurer__banner__text {
        background: $red;
      }

      .insurer__banner__arrow {
        border-color: transparent transparent transparent $red;
      }
    }

    &--gray {
      .insurer__banner__text {
        background: $bodyColor;
      }

      .insurer__banner__arrow {
        border-color: transparent transparent transparent $bodyColor;
      }
    }

    &--artus {
      .insurer__banner__text {
        background: #FFB200;
      }

      .insurer__banner__arrow {
        border-color: transparent transparent transparent #FFB200;
      }
    }
  }

  &__head {
    height: 40px;
    padding: 8px;
    border-bottom: 1px solid $greyTone;
    background: mix($greyTone, #fff, 30%);
  }

  &__body {
    padding: 8px;
  }

  &__col1,
  &__col2 {
    padding: 4px 8px;
    width: 100%;
    font-size: 0.8rem;

    @include media-breakpoint-up(md) {
      padding: 8px 0;
    }

    ul {
      margin-left: 8px;
      margin-bottom: 0;
    }
  }

  &__col1 {
    @include media-breakpoint-up(md) {
      width: 27.5%;
    }
  }

  &__col2 {
    @include media-breakpoint-up(md) {
      width: 17.5%;
    }
  }

  &__image {
    padding: 0 16px 16px 16px;

    img {
      display: block;
      width: 100%;
      max-width: 140px;
      margin: 0 auto;
    }

    &--markel,
    &--markel-standard {
      img {
        max-width: 100px;
        width: auto;
      }
    }

    &--hdi {
      img {
        max-width: 120px;
        width: auto;
      }
    }

    &--hdi-vodafone {
      img {
        max-width: 120px;
        width: auto;
      }
    }

    &--axa {
      img {
        max-width: 120px;
        width: auto;
      }
    }

    &--allianz {
      img {
        min-width: 120px;
      }
    }
  }

  &--disabled {
    .insurer__banner__text {
      background: $redTone;
    }

    .insurer__banner__arrow {
      border-color: transparent transparent transparent $redTone;
    }

    .insurer__image, .insurer__col2, .custom-control, .insurer__details {
      opacity: 0.5;
      filter: grayscale(100%);
    }
  }
}

.price {
  padding: 4px;
  width: 100%;

  @include media-breakpoint-up(md) {
    padding: 8px;
  }

  &__amount {
    font-weight: bold;
    font-size: 1.3rem;
  }

  &__note {
    font-size: .7rem;
  }

  &__cta {
    > button {
      font-weight: bold;
    }
  }
}

.loading-overlay {
  position: fixed;
  z-index: $zIndexLoading;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  user-select: none;
  opacity: 0;
  will-change: opacity;
  transition: opacity .25s;

  &--in {
    opacity: 1;
  }
}

.optional {
  display: none;

  + label {
    position: relative;
    user-select: none;
    cursor: pointer;
    margin-bottom: 0;
    display: inline-block;
    height: 24px;
    width: 24px;
    background-image: url('https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/checkout/images/radio_btn_optional_normal.svg');
  }

  &:checked + label {
    background-image: url('https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/checkout/images/check_optional_selected.svg');
  }
}

.circle-icon {
  width: 24px;

  &--with-tooltip {
    cursor: pointer;
  }
}

.controls {
  padding: 16px;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -85px;
  display: flex;
  flex-direction: row;
  background: $lightGrey;
  border-bottom: 1px solid $lightGrey3;
}

.text-underline {
  text-decoration: underline;
}

.comparison-button {
  position: fixed;
  padding: 8px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  > .btn {
    font-weight: bold;
  }
}

.custom-control-label {
  color: $primary;
  line-height: 24px;
  font-weight: bold;
}

.custom-checkbox .custom-control-label {
  font-weight: 400;
}

.btn-primary {
  &:disabled {
    background: $bodyColor;
    border-color: $bodyColor;
    border: none;
  }
}

.footer-btns--produktauswahl {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;

  .btn {
    width: 100%;
    white-space: normal;
  }

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }
}
</style>
