<template>
  <div class="secondary-page">
    <div class="container" v-if="offer">
      <div class="row page-title">
        <div class="col-12 back-btn text-right">
          <a class="btn-style" href="" @click.prevent.stop="takeMeBack()">&larr;Zurück</a>
        </div>
        <div class="col-12">
          <h1>Angebot: {{ offer.firstname }} {{ offer.lastname }}</h1>
          <p>Status: {{ getState() }}</p>
          <div class="d-flex flex-column flex-md-row toolbar">
            <button class="btn btn-secondary" :class="{'btn-gray-out': isOfferAccepted}" @click="open()" target="_blank"
                    :disabled="!isEditable || !offer.selection.length">
              Zum Angebot
            </button>
            <button class="btn btn-secondary" :class="{'btn-gray-out': isOfferAccepted}" @click="copy()"
                    :disabled="!isEditable || !offer.selection.length">
              Link zum Angebot kopieren
            </button>
            <button class="btn btn-secondary" :class="{'btn-gray-out': isOfferAccepted}" @click="sendModalOpen = true"
                    :disabled="!isEditable || !offer.selection.length">
              Angebot per E-Mail versenden
            </button>
            <div id="checkout-btn" class="btn-wrap">
              <button class="btn btn-secondary"  @click="checkout()"
                    :disabled="!isEditable || !offer.selection.length || offer.insurer === 'no-recommendation' || insurerFull.disabled">
                Antrag einreichen
              </button>
            </div>
            <b-tooltip v-if="!offer.selection.length || offer.insurer === 'no-recommendation' || insurerFull.disabled" target="checkout-btn" customClass="tooltip-basic">Für die Einreichung eines Antrags müssen ein Versicherer als Empfehlung ausgewählt und die Risikofragen vollständig beantwortet werden. Bitte vergewissern Sie sich, dass Sie diese Schritte unternommen haben. </b-tooltip>

          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-9 order-3 order-lg-1">
          <div class="row">
            <div class="col-12 col-md-6">
              <table class="table editable-table">
                <thead class="thead-light">
                <tr>
                  <th scope="col">Kunde</th>
                  <th class="controls">
                                <span v-if="!editMode">
                                  <button class="btn-icon"
                                          type="button"
                                          v-if="isEditable"
                                          @click="editCustomerInfo()">
                                      <icon name="pencil" color="dark-grey"></icon>
                                  </button>
                                </span>
                    <span v-if="editMode">
                                  <button class="btn-icon" type="button" @click="cancelEditMode()">
                                      <icon name="cancel" color="red"></icon>
                                  </button>
                                  <button class="btn-icon" type="button" @click="saveCustomerInfo()">
                                      <icon name="check" color="primary"></icon>
                                  </button>
                                </span>
                  </th>
                </tr>
                </thead>
                <tbody>
                  <tr v-if="isOfferLoading">
                    <td colspan="2">
                      <div class="loader-wrapper">
                        <LoaderComponent />
                      </div>
                    </td>
                  </tr>
                  <template v-else>
                    <tr>
                      <td>Firma</td>
                      <td class="pre-break" v-if="!editMode">{{ offer.company }}</td>
                      <td v-if="editMode"><input class="form-control" v-model="offer.company"></td>
                    </tr>
                    <tr>
                      <td>Anrede</td>
                      <td class="pre-break" v-if="!editMode">{{ offer.salutation }}</td>
                      <td v-if="editMode">
                        <select class="form-control" v-model="offer.salutation">
                          <option value="Herr">Herr</option>
                          <option value="Frau">Frau</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>Vorname</td>
                      <td class="pre-break" v-if="!editMode">{{ offer.firstname }}</td>
                      <td v-if="editMode"><input class="form-control" v-model="offer.firstname"></td>
                    </tr>
                    <tr>
                      <td>Nachname</td>
                      <td class="pre-break" v-if="!editMode">{{ offer.lastname }}</td>
                      <td v-if="editMode"><input class="form-control" v-model="offer.lastname"></td>
                    </tr>
                    <tr>
                      <td>E-Mail</td>
                      <td class="pre-break" v-if="!editMode">{{ offer.email }}</td>
                      <td v-if="editMode"><input class="form-control" v-model="offer.email"></td>
                    </tr>
                    <tr>
                      <td>Telefon</td>
                      <td v-if="!editMode">{{ offer.phone }}</td>
                      <td v-else>
                        <TelephoneForm
                        formId="offerPhoneNumber"
                        :phoneNumber='offer.phone'
                        :emulateTouch='emulateTouch.offerPhone'
                        :showFlags="false"
                        @numberChange="updatePhoneNumber"/>
                      </td>
                    </tr>
                    <tr>
                      <td>Grund für die Wahl</td>
                        <td v-if="!editMode" class="pre-break">{{ reason }}</td>
                        <td v-if="editMode">
                          <textarea class="form-control" v-model="reason"></textarea>
                        </td>
                    </tr>
                    <tr>
                      <td>Kommentar</td>
                      <td v-if="!editMode" class="pre-break">{{ offer.comment }}</td>
                      <td v-if="editMode">
                        <textarea class="form-control" v-model="offer.comment"></textarea>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
              <table class="table">
                <thead class="thead-light">
                <tr>
                  <th scope="col" colspan="2">Umsatz & Branche</th>
                </tr>
                </thead>
                <tbody>
                  <tr v-if="isOfferLoading">
                    <td colspan="2">
                      <div class="loader-wrapper">
                        <LoaderComponent />
                      </div>
                    </td>
                  </tr>
                  <template v-else>
                    <tr>
                      <td>Branche</td>
                      <td>{{ offer.industry.l }} (Branchen-Code: {{ offer.industry.w }})</td>
                    </tr>
                    <tr>
                      <td>Umsatz</td>
                      <td>{{ offer.netTurnOver | currency(0) }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="col-12 col-md-6 table-wrapper">
              <table class="table editable-table">
                <thead class="thead-light">
                <tr>
                  <th scope="col">Versicherung</th>
                  <th class="controls">
                                <span v-if="!insuranceEditMode">
                                  <button class="btn-icon"
                                          type="button"
                                          v-if="isEditable"
                                          @click="editInsuranceInfo()">
                                      <icon name="pencil" color="dark-grey"></icon>
                                  </button>
                                </span>
                    <span v-if="insuranceEditMode">
                                  <button class="btn-icon" type="button" @click="cancelEditMode()">
                                      <icon name="cancel" color="red"></icon>
                                  </button>
                                  <button class="btn-icon" type="button" @click="saveCustomerInfo()">
                                      <icon name="check" color="primary"></icon>
                                  </button>
                                </span>
                  </th>
                </tr>
                </thead>
                <tbody :class="{'is-data-loading': isInsuranceLoading}">
                  <tr v-if="isInsuranceLoading" class="loading-anim">
                    <td colspan="2">
                      <div class="loader-wrapper">
                        <LoaderComponent />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div v-show="isSelectionLoading" class="loader-wrapper">
                        <LoaderComponent />
                      </div>
                      <table v-show="!isSelectionLoading" class="table">
                        <thead>
                        <tr>
                          <th>im Vergleich</th>
                          <th>Empfehlung</th>
                          <th>Entfernen</th>
                        </tr>
                        </thead>
                          <tbody>
                            <tr v-for="insurerKey in sortedSelection"
                              :key="insurerKey">
                              <td>{{ getInsurerName(insurerKey) }}</td>
                              <td>
                                <div class="custom-control custom-radio">
                                  <input class="custom-control-input"
                                        type="radio"
                                        :id="`radio-${insurerKey}`"
                                        v-model="offer.insurer"
                                        :value="insurerKey"
                                        :disabled="!insuranceEditMode"
                                        name="selectedInsurer">
                                  <label class="custom-control-label"
                                        :for="`radio-${insurerKey}`"></label>
                                </div>
                              </td>
                              <td>
                                <button class="btn btn-icon" type="button"
                                        :disabled="!insuranceEditMode"
                                        @click="removeSelectedInsurer(insurerKey)">
                                  <icon name="delete" color="dark-grey"></icon>
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td>keine Empfehlung</td>
                              <td colspan="2">
                                <div class="custom-control custom-radio">
                                  <input class="custom-control-input"
                                        type="radio"
                                        id="radio-no-recommendation"
                                        v-model="offer.insurer"
                                        value="no-recommendation"
                                        :disabled="!insuranceEditMode"
                                        name="selectedInsurer">
                                  <label class="custom-control-label"
                                        for="radio-no-recommendation"></label>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                      </table>

                      <table class="rating-option">
                        <tr>
                          <td>Rating im Angebot anzeigen</td>
                          <td class="control-cell d-flex justify-content-end">
                            <SwitchCheckbox
                                v-model="offer.showRating"
                                :disabled="!insuranceEditMode ? true : false"
                                class="mb-3"></SwitchCheckbox>
                          </td>
                        </tr>
                        <tr>
                          <td :class="{'label-disabled': !offer.selection.includes(pricePerformWinner.key)}">Preis-Leistungs-Sieger im Angebot anzeigen</td>
                          <td class="control-cell d-flex justify-content-end">
                            <SwitchCheckbox
                                v-model="showPricePerformWinner"
                                :disabled="(!insuranceEditMode ? true : false) || !offer.selection.includes(pricePerformWinner.key)"
                                class="mb-3"></SwitchCheckbox>
                          </td>
                        </tr>
                      </table>
                      <div class="input-group mb-2">
                        <select class="form-control"
                                name="salutation"
                                id="salutation"
                                :disabled="offer.selection.length >= 4 || !insuranceEditMode || !additionalInsurers.length"
                                v-model="additionalInsurer">
                          <option :value="insurer.key" v-for="insurer in additionalInsurers">
                            {{ insurer.label }}
                          </option>
                        </select>
                        <div class="input-group-append">
                          <button class="btn btn-secondary"
                                  type="button"
                                  @click="addAdditionalInsurer()"
                                  :disabled="offer.selection.length >= 4 || !additionalInsurer || !insuranceEditMode">
                            hinzufügen (max. 4)
                          </button>
                        </div>
                      </div>
                      <div class="alert alert-danger mb-2"
                          v-if="!additionalInsurers.length && offer.selection.length === 0">Für diese
                        Einstellungen ist kein Versicherer verfügbar.
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Sortieren nach</td>
                    <td class="pre-break" v-if="!insuranceEditMode">
                        <span v-if="offer.sortBy ==='abc'">Alphabetisch</span>
                        <span v-if="offer.sortBy ==='score' || !offer.sortBy">Leistung</span>
                        <span v-if="offer.sortBy ==='price'">Preis</span>
                        <span v-if="offer.sortBy ==='price-score-ratio'">Preis/Leistung</span>
                    </td>
                    <td v-if="insuranceEditMode">
                      <select class="form-control" v-model="offer.sortBy">
                        <option value="abc">Alphabetisch</option>
                        <option value="score">Leistung</option>
                        <option value="price">Preis</option>
                        <option value="price-score-ratio">Preis/Leistung</option>
                      </select>
                    </td>
                  </tr>
                  <tr v-if="insuredSelected">
                    <td>Prämie (jährlich, netto)</td>
                    <td>{{ offer.net | currency(2) }}</td>
                  </tr>
                  <tr v-if="insuredSelected">
                    <td>Prämie (jährlich, brutto)</td>
                    <td>{{ offer.gross | currency(2) }}</td>
                  </tr>
                  <tr class="insured-sum-row">
                    <td>Versicherungssumme 
                      <icon id="insured-sum-info" name="info" color="dark-grey" class="insured-sum-tooltip-trigger"></icon>
                    </td>
                    <td v-if="!insuranceEditMode">{{ offer.insuredSum | currency(0) }}</td>
                    <td v-if="insuranceEditMode">
                      <select class="form-control"
                              name="insuredSumSelect"
                              id="insuredSumSelect"
                              v-model="offer.insuredSum"
                              v-validate="'required'">
                        <option v-for="insuredSum in insuredSums" :value="insuredSum">
                          {{ insuredSum | currency(0) }}
                        </option>
                      </select>
                    </td>

                    <b-tooltip target="insured-sum-info" customClass="tooltip-basic">Sie haben die Möglichkeit, Ihrem Kunden im Angebot bis zu zwei weitere Versicherungssummen inklusive der dazugehörigen Bruttoprämien anzuzeigen. </b-tooltip>

                     <!-- can be deleted at 2024-3-12... -->
                     <NewFeature
                      :showUntil="new Date('2024-3-12')"
                      :featureId="'insured-sum-option-notification'"
                      class="insured-sum-option-notification"
                      >
                      <b>Jetzt neu:</b> Zeigen Sie Ihrem Kunden mehrere Versicherungssummen an.
                    </NewFeature>

                  </tr>
                  <tr class="additional-option">
                    <td class="additional-option__label">Alternative 1</td>
                    <td v-if="!insuranceEditMode">{{ offer.insuredSumOption1 | currency(0) }}</td>
                    <td v-if="insuranceEditMode">
                      <select class="form-control"
                              name="insuredSumSelect"
                              id="insuredSumSelect"
                              v-model="offer.insuredSumOption1"
                              v-validate="'required'">
                        <option value=""></option>
                        <option v-for="insuredSum in insuredSums" :value="insuredSum">
                          {{ insuredSum | currency(0) }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr class="additional-option">
                    <td class="additional-option__label">Alternative 2</td>
                    <td v-if="!insuranceEditMode">{{ offer.insuredSumOption2 | currency(0) }}</td>
                    <td v-if="insuranceEditMode">
                      <select class="form-control"
                              name="insuredSumSelect"
                              id="insuredSumSelect"
                              :disabled="!offer.insuredSumOption1 && !offer.insuredSumOption2"
                              v-model="offer.insuredSumOption2"
                              v-validate="'required'">
                        <option value=""></option>
                        <option v-for="insuredSum in insuredSums" :value="insuredSum">
                          {{ insuredSum | currency(0) }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Selbstbehalt</td>
                    <td v-if="!insuranceEditMode">{{ offer.deductible | currency(0) }}</td>
                    <td v-if="insuranceEditMode">
                      <select class="form-control"
                              name="deductibleSelect"
                              id="deductibleSelect"
                              v-model="offer.deductible"
                              v-validate="'required'">
                        <option v-for="deductible in possibleDeductibles" :value="deductible">
                          {{ deductible | currency(0) }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Zahlungsweise</td>
                    <td v-if="!insuranceEditMode">{{ paymentPeriod }}</td>
                    <td v-if="insuranceEditMode">
                      <select class="form-control"
                        name="paymentPeriodSelect"
                        id="paymentPeriodSelect"
                        v-model="offer.paymentPeriod"
                        v-validate="'required'">
                          <option :value="key" v-for="(p, key) in possiblePaymentPeriods" :key="key">
                          {{ p.label }}
                        </option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table class="table editable-table"
                     v-if="offer.selectedModules && offer.selectedModules[offer.insurer]">
                <thead class="thead-light">
                <tr>
                  <th scope="col">
                    Tarifbausteine
                  </th>
                  <th class="controls">
                                <span v-if="!modulesEditMode">
                                  <button class="btn-icon"
                                          type="button"
                                          v-if="isEditable"
                                          @click="editModulesInfo">
                                      <icon name="pencil" color="dark-grey"></icon>
                                  </button>
                                </span>
                    <span v-if="modulesEditMode">
                                  <button class="btn-icon" type="button" @click="cancelEditMode()">
                                      <icon name="cancel" color="red"></icon>
                                  </button>
                                  <button class="btn-icon" type="button" @click="saveModuleInfo()">
                                      <icon name="check" color="primary"></icon>
                                  </button>
                                </span>
                  </th>
                </tr>
                </thead>
                <tbody>
                  <tr v-if="isOfferLoading">
                    <td colspan="2">
                      <div class="loader-wrapper">
                        <LoaderComponent />
                      </div>
                    </td>
                  </tr>
                  <template v-else>
                    <tr v-for="(property, ind) in selectedModulesFiltered[offer.insurer]" :key="ind">
                      <td>{{ property.label }}</td>
                      <td class="control-cell d-flex justify-content-end">
                        <div class="custom-control custom-checkbox" v-if="modulesEditMode">
                          <input type="checkbox"
                                class="custom-control-input"
                                :id="property.key + 'Check'"
                                v-on:change="optionalChanged(property.key, property.selected)"
                                v-model="offer.selectedModules[offer.insurer]"
                                :value="property.key">
                          <label class="custom-control-label"
                                :for="property.key + 'Check'"></label>
                        </div>
                        <div v-else>
                          <icon name="check" color="dark-grey"
                                v-if="offer.selectedModules[offer.insurer].indexOf(property.key) > -1"></icon>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>

              <!--<table class="table editable-table" v-if="isBrokerChangeVisible">-->
              <!--<thead class="thead-light">-->
              <!--<tr>-->
              <!--<th scope="col">-->
              <!--Makler zuweisen-->
              <!--</th>-->
              <!--<th class="controls">-->
              <!--<span v-if="!brokerEditMode">-->
              <!--<button class="btn-icon"-->
              <!--type="button"-->
              <!--@click="editBroker">-->
              <!--<icon name="pencil" color="dark-grey"></icon>-->
              <!--</button>-->
              <!--</span>-->
              <!--<span v-if="brokerEditMode">-->
              <!--<button class="btn-icon" type="button" @click="cancelEditMode()">-->
              <!--<icon name="cancel" color="red"></icon>-->
              <!--</button>-->
              <!--<button class="btn-icon" type="button" @click="saveBroker()">-->
              <!--<icon name="check" color="primary"></icon>-->
              <!--</button>-->
              <!--</span>-->
              <!--</th>-->
              <!--</tr>-->
              <!--</thead>-->
              <!--<tbody>-->
              <!--<tr>-->
              <!--<td colspan="2">-->
              <!--<label for="offerAgency">Agentur</label>-->
              <!--<select class="form-control"-->
              <!--v-model="selectedAgency"-->
              <!--:disabled="!brokerEditMode"-->
              <!--id="offerAgency"-->
              <!--name="offerAgency">-->
              <!--<option v-for="agency in agencies" v-bind:value="agency.id">-->
              <!--{{agency.name}}-->
              <!--</option>-->
              <!--</select>-->
              <!--</td>-->
              <!--</tr>-->
              <!--<tr>-->
              <!--<td colspan="2">-->
              <!--<label for="offerBroker">Makler</label>-->
              <!--<select class="form-control"-->
              <!--v-model="offer.createdBy"-->
              <!--:disabled="!brokerEditMode"-->
              <!--id="offerBroker"-->
              <!--name="offerBroker">-->
              <!--<option v-for="broker in brokers" v-bind:value="broker.id">-->
              <!--{{broker.firstname}} {{broker.lastname}}-->
              <!--</option>-->
              <!--</select>-->
              <!--</td>-->
              <!--</tr>-->
              <!--</tbody>-->
              <!--</table>-->

              <table class="table editable-table" v-if="isBrokerChangeVisible">
                <thead class="thead-light">
                <tr>
                  <th scope="col">
                    Makler:in zuweisen
                  </th>
                  <th class="controls">
                                <span v-if="!brokerEditMode">
                                  <button class="btn-icon"
                                          type="button"
                                          @click="editBroker">
                                      <icon name="pencil" color="dark-grey"></icon>
                                  </button>
                                </span>
                    <span v-if="brokerEditMode">
                                  <button class="btn-icon" type="button" @click="cancelEditMode()">
                                      <icon name="cancel" color="red"></icon>
                                  </button>
                                  <button class="btn-icon" type="button" @click="saveBroker()">
                                      <icon name="check" color="primary"></icon>
                                  </button>
                                </span>
                  </th>
                </tr>
                </thead>
                <tbody>
                  <tr v-if="isOfferLoading">
                    <td colspan="2">
                      <div class="loader-wrapper">
                        <LoaderComponent />
                      </div>
                    </td>
                  </tr>
                  <template v-else>
                    <tr v-if="isAgencyChangeVisible">
                      <td colspan="2">
                        <label for="offerAgency">Agentur</label>
                        <select class="form-control"
                                v-model="selectedAgency"
                                :disabled="!brokerEditMode"
                                id="offerAgency"
                                name="offerAgency">
                          <option v-for="agency in agencies" v-bind:value="agency.id">
                            {{ agency.name }}
                          </option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <label for="offerBroker">Makler:in</label>
                        <select class="form-control"
                                v-model="selectedBroker"
                                :disabled="!brokerEditMode"
                                id="offerBroker"
                                name="offerBroker">
                          <option v-for="broker in brokers" v-bind:value="broker.id">
                            {{ broker.firstname }} {{ broker.lastname }}
                          </option>
                        </select>
                      </td>
                    </tr>
                    <tr v-if="isAssignBackVisible">
                      <td colspan="2">
                        <button class="btn btn-secondary mt-3" @click="assignOfferBack()">der
                          Hauptagentur zuordnen
                        </button>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>

              <table class="table editable-table">
                <thead class="thead-light">
                <tr>
                  <th scope="col">
                    Interne Notiz
                  </th>
                  <th class="controls">
                                <span v-if="!noteEditMode">
                                  <button class="btn-icon"
                                          type="button"
                                          @click="editNote">
                                      <icon name="pencil" color="dark-grey"></icon>
                                  </button>
                                </span>
                    <span v-if="noteEditMode">
                                  <button class="btn-icon" type="button" @click="cancelEditMode()">
                                      <icon name="cancel" color="red"></icon>
                                  </button>
                                  <button class="btn-icon" type="button" @click="saveCustomerInfo()">
                                      <icon name="check" color="primary"></icon>
                                  </button>
                                </span>
                  </th>
                </tr>
                </thead>
                <tbody>
                  <tr v-if="isOfferLoading">
                    <td colspan="2">
                      <div class="loader-wrapper">
                        <LoaderComponent />
                      </div>
                    </td>
                  </tr>
                  <template v-else>
                    <tr>
                      <td colspan="2" v-if="!noteEditMode" class="pre-break">{{ offer.description }}</td>
                      <td colspan="2" v-if="noteEditMode">
                        <textarea class="form-control" v-model="offer.description"></textarea>
                        <small>Kann nicht vom Kunden eingesehen werden</small>
                      </td>
                    </tr>  
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-lg-3 timeline order-2" v-if="isOfferLoading">
          <div class="loader-wrapper">
            <LoaderComponent />
          </div>
        </div>
        <div class="col-lg-3 timeline order-2" v-else-if="timeline && timeline.length">
          <div class="timeline__item" v-for="item in timeline" :class="{'timeline__item--done': item.done}" :key="item.id">
            <div class="timeline__item__icon" v-if="item.done">
              <icon name="check"></icon>
            </div>
            <div class="timeline__item__text">{{ item.state }}</div>
            <div class="timeline__item__date d-none d-md-block" v-if="item.date">{{ item.date | date('DD.MM.YYYY HH:mm') }}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table class="table editable-table">
            <thead class="thead-light">
            <tr>
              <th scope="col"
                  >Bemerkungen Beratungsprotokoll
                  <icon class="ml-2 align-bottom" name="info" color="dark-grey"
                  v-tooltip.top="'Text wird im Beratungsprotokoll hinterlegt'"></icon>
              </th>
              <th class="controls">
                                <span v-if="!commentEditMode">
                                  <button class="btn-icon"
                                          type="button"
                                          v-if="isEditable"
                                          @click="editComment()">
                                      <icon name="pencil" color="dark-grey"></icon>
                                  </button>
                                </span>
                <span v-if="commentEditMode">
                                  <button class="btn-icon" type="button" @click="cancelEditMode()">
                                      <icon name="cancel" color="red"></icon>
                                  </button>
                                  <button class="btn-icon" type="button" @click="saveCustomerInfo()">
                                      <icon name="check" color="primary"></icon>
                                  </button>
                                </span>
              </th>
            </tr>
            </thead>
            <tbody>
              <tr v-if="isOfferLoading">
                <td colspan="2">
                  <div class="loader-wrapper">
                    <LoaderComponent />
                  </div>
                </td>
              </tr>
              <tr v-else>
                <td v-if="commentEditMode" colspan="2">
                                          <textarea class="form-control"
                                                    name="editReason"
                                                    id="editReason"
                                                    ref="editReasonField"
                                                    v-model="offer.editReason"
                                                    rows="6"></textarea>
                </td>
                <td v-else colspan="2" class="white-space-pre-line">
                  {{ offer.editReason }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row" v-if="insuredSelected">
        <div class="col-12 table-wrapper">
          <table class="table">
            <thead class="thead-light">
            <tr>
              <th scope="col">
                Risikofragen
              </th>
              <th class="controls text-right">
                                <span v-if="!riskQuestionEditMode">
                                  <button class="btn-icon"
                                          type="button"
                                          v-if="isEditable"
                                          @click="editRiskQuestion()">
                                      <icon name="pencil" color="dark-grey"></icon>
                                  </button>
                                </span>
                <span v-if="riskQuestionEditMode">
                                  <button class="btn-icon" type="button" @click="cancelEditMode()">
                                      <icon name="cancel" color="red"></icon>
                                  </button>
                                  <button class="btn-icon" type="button" @click="saveRiskQuestions()">
                                      <icon name="check" color="primary"></icon>
                                  </button>
                                </span>
              </th>
            </tr>
            </thead>
            <tbody>
              <tr v-if="isOfferLoading">
                <td colspan="2">
                  <div class="loader-wrapper">
                    <LoaderComponent />
                  </div>
                </td>
              </tr>
              <template v-else>
                <tr v-for="(question, questionIndex) in filteredQuestions" :key="questionIndex">
                  <td class="question-title">
                    <div>{{ (questionIndex + 1) }}. {{ question.title }}</div>
                    <p v-if="question.text">{{ question.text }}</p>
                    <ul v-if="question.list">
                      <li v-for="(li, index) in question.list" :key="index" :class="{'question__list--subtext':li.subText}">
                        <span v-if="li.text" v-html="$sanitizeHtml(li.text)"></span>
                        <span v-if="li.subText" v-html="$sanitizeHtml(li.subText)"></span>
                        <div class="alert alert-dark d-flex flex-row" role="alert" v-if="li.tooltip">
                          <icon class="mr-2" name="info" color="dark-grey" :width="28"></icon>
                          <span v-html="li.tooltip"></span>
                        </div>
                      </li>
                    </ul>
                    <div class="alert alert-dark d-flex flex-row" role="alert" v-if="question.tooltip">
                      <icon class="mr-2" name="info" color="dark-grey" :width="28"></icon>
                      <span v-html="question.tooltip"></span>
                    </div>
                    <div class="alert alert-info"
                        v-if="question.warning && question.warning[offer.answers[`q-${question.id}`]]"
                        v-html="question.warning[offer.answers[`q-${question.id}`]]">
                    </div>
                    <div v-if="question.form">
                      <div class="form-group"
                          v-for="(field, index) in getForm(question)" :key="index"
                          :class="getFieldClass(field.name)">
                        <label>{{ field.label }}</label>
                        <input class="form-control"
                              type="text"
                              v-if="field.type === 'text'"
                              :id="field.name"
                              :name="field.name"
                              :disabled="!riskQuestionEditMode"
                              v-validate="{ required: true }"
                              v-model="offer.form[question.id][field.name]"/>
                        <textarea class="form-control"
                                  v-else-if="field.type === 'textarea'"
                                  :id="field.name"
                                  :name="field.name"
                                  :disabled="!riskQuestionEditMode"
                                  v-validate="{ required: true }"
                                  v-model="offer.form[question.id][field.name]"></textarea>
                        <datepicker v-else-if="field.type === 'datePicker'"
                                    format="dd.MM.yyyy"
                                    input-class="form-control"
                                    :language="de"
                                    :monday-first="true"
                                    calendar-button-icon="fa fa-calendar"
                                    :disabledDates="disabledFormDates"
                                    v-model="offer.form[question.id][field.name]"></datepicker>
                        <form class="address-repeater"
                              v-else-if="field.type === 'address-repeater'"
                              autocomplete="off">
                          <div class="row">
                            <div class="col-12 col-sm-6">
                              <div class="form-group">
                                <label for="spName">Unternehmen</label>
                                <input class="form-control sp-input"
                                      type="text"
                                      name="spName"
                                      id="spName"
                                      :disabled="!riskQuestionEditMode"
                                      v-model="serviceProviderString">
                                <typeahead v-model="selectedServiceProvider"
                                          target=".sp-input"
                                          :data="serviceProvider"
                                          item-key="name">
                                  <template slot="item" slot-scope="props">
                                    <li v-for="item in props.items">
                                      <a class="dropdown-item"
                                        role="button"
                                        @click="props.select(item);spSelected()">
                                        <span v-html="props.highlight(item)"></span>
                                      </a>
                                    </li>
                                  </template>
                                </typeahead>
                              </div>
                            </div>
                            <div class="col-12 col-sm-6">
                              <div class="form-group">
                                <label for="street">Straße und Hausnummer</label>
                                <input class="form-control"
                                      type="text"
                                      name="street"
                                      id="street"
                                      :disabled="!riskQuestionEditMode"
                                      v-model="formData.street">
                              </div>
                            </div>
                            <div class="col-12 col-sm-6">
                              <div class="form-group">
                                <label for="city">Plz und Ort</label>
                                <input class="form-control"
                                      type="text"
                                      name="city"
                                      id="city"
                                      :disabled="!riskQuestionEditMode"
                                      v-model="formData.city">
                              </div>
                            </div>
                            <div class="col-12 col-sm-6">
                              <div class="form-group">
                                <label for="country">Land</label>
                                <input class="form-control"
                                      type="text"
                                      name="country"
                                      id="country"
                                      :disabled="!riskQuestionEditMode"
                                      v-model="formData.country">
                              </div>
                            </div>
                          </div>
                          <button class="btn btn-primary mb-3"
                                  type="button"
                                  :disabled="!riskQuestionEditMode"
                                  @click="addServiceProvider(question.id, field.name)">hinzufügen
                          </button>
                          <table class="table"
                                v-if="offer.form[question.id][field.name]">
                            <thead>
                            <tr>
                              <th>Dienstleister</th>
                              <th>Adresse</th>
                              <th class="text-center">Löschen</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(provider, index) in offer.form[question.id][field.name]">
                              <td>{{ provider.name }}</td>
                              <td>{{ provider.street }}<br>{{ provider.city }}<br>{{ provider.country }}
                              </td>
                              <td class="text-center">
                                <button class="btn btn-icon"
                                        type="button"
                                        @click="removeServiceProvider(question.id, field.name, index)">
                                  <icon name="cancel" color="secondary"></icon>
                                </button>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </form>
                        <form class="subsidiary-repeater"
                              v-else-if="field.type === 'subsidiary-repeater'"
                              autocomplete="off">
                          <div class="row">
                            <div class="col-12 col-sm-6">
                              <div class="form-group">
                                <label for="city">Land</label>
                                <input class="form-control"
                                      type="text"
                                      name="country"
                                      id="country"
                                      :disabled="!riskQuestionEditMode"
                                      v-model="formSubsidiaryData.country">
                              </div>
                            </div>
                            <div class="col-12 col-sm-6">
                              <div class="form-group">
                                <label for="country">Umsatz</label>
                                <input class="form-control"
                                      type="text"
                                      name="revenue"
                                      id="revenue"
                                      :disabled="!riskQuestionEditMode"
                                      v-model="formSubsidiaryData.revenue">
                              </div>
                            </div>
                          </div>
                          <button class="btn btn-secondary mb-3"
                                  type="button"
                                  :disabled="!riskQuestionEditMode"
                                  @click="addSubsidiary(question.id, field.name)">hinzufügen
                          </button>
                          <table class="table"
                                v-if="offer.form[question.id][field.name].length > 0">
                            <thead>
                            <tr>
                              <th>Land</th>
                              <th>Umsatz</th>
                              <th class="text-center">Löschen</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(subsidiary, index) in offer.form[question.id][field.name]"
                                :key="subsidiary.name">
                              <td>{{ subsidiary.country }}</td>
                              <td>{{ subsidiary.revenue }}</td>
                              <td class="text-center">
                                <button class="btn btn-icon"
                                        type="button"
                                        @click="removeServiceProvider(question.id, field.name, index)">
                                  <icon name="cancel" color="secondary"></icon>
                                </button>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </form>
                      </div>
                    </div>
                  </td>
                  <td v-if="!riskQuestionEditMode" class="question-answer">
                    {{ getAnswer(offer.answers, question) }}
                    <icon class="question-error"
                          name="error"
                          color="red"
                          v-if="!isAnswerValid(question)"
                          v-tooltip.top="'Mit diesen Angaben erfüllen Sie nicht die IT-Mindestanforderungen des Versicherers. Der Antrag kann trotzdem eingereicht werden und wird vom Versicherer individuell geprüft.'"></icon>
                  </td>
                  <td class="question-answer" v-if="riskQuestionEditMode && !(question.form && question.form.fields)">
                    <select
                      name="test"
                      id="test"
                      v-model="offer.answers[`q-${question.id}`]"
                      @change="handleRiskAnswerChange($event.target.value, `q-${question.id}`)"
                      v-if="getAnswers(question).length > 1">
                      <option :value="undefined">Unbeantwortet</option>
                      <option :value="answer.value" v-for="answer in getAnswers(question)">
                        {{ answer.label }}
                      </option>
                    </select>
                    <icon class="question-error"
                          name="error"
                          color="red"
                          v-if="!isAnswerValid(question)"
                          v-tooltip.top="'Frage wurde nicht korrekt beantwortet. Der Antrag kann trotzdem eingereicht werden.'"></icon>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <modal2 v-if="sendModalOpen" @close="sendModalOpen = false" :closeable="true" :small="true">
      <h4 class="mb-4" slot="header">
        Angebot per E-Mail versenden
      </h4>
      <div slot="body">
        <div class="send-variations">
          <ButtonComponent
            behavior="externalLink"
            :href="`mailto:${email ? email : ''}?subject=Ihre Cyber-Versicherung&cc=${user.email}&body=${getSalutation()}%0A%0A${offer.reason}%0A%0A${offer.comment}%0A%0AAngebot einsehen: ${offer.link}%0A%0AMit freundlichen Grüßen%0A%0A${user.firstname} ${user.lastname}%0D${user.brokerAgency.company}`">
            Angebot von Ihrem Email-Postfach versenden
          </ButtonComponent>
          <ButtonComponent @click.native="sendModalByCyberOpen = true; sendModalOpen = false">
            Angebot von CyberDirekt versenden
          </ButtonComponent>
        </div>
      </div>
    </modal2>
    <modal2 v-if="sendModalByCyberOpen" @close="sendModalByCyberOpen = false" :closeable="true">
      <h4 class="mb-4" slot="header">
        Angebot per E-Mail versenden
      </h4>
      <div slot="body">
        <div class="form-group mb-3">
          <label for="email">E-Mail Ihres Kunden</label>
          <input class="form-control"
                 type="email"
                 id="email"
                 key="email"
                 v-validate="{ required: true, email: true }"
                 v-model="email">
        </div>
        <div class="form-group mb-4">
          <label for="ccEmail">CC: </label>
          {{ user.email }}
        </div>
        <!-- (?) not sure what "main-text" below should be assigned to -->
        <EmailPreview :salutation="getSalutation()" :main-text="{reason: offer.reason, comment: offer.comment}" :offer-link="offer.link" />
      </div>
      <button type="button"
              @click="send()"
              class="btn-style primary"
              slot="footer">E-Mail senden

      </button>
    </modal2>
    <modal2 v-if="riskQuestionModalOpen" @close="riskQuestionModalOpen = false" :closeable="true" :small="true">
      <h4 class="mb-4" slot="header">
        {{ riskQuestionModalHeading }}
      </h4>
      <div slot="body">
        <p>{{ riskQuestionModalText }}</p>
      </div>
      <div slot="footer" v-if="riskQuestionModalFooter" class="footer-btns">
        <button type="button"
                @click="riskQuestionModalOpen = false"
                class="btn-style">abbrechen
        </button>
        <button type="button"
                @click="sendCheckout"
                class="btn-style primary">Trotzdem einreichen
        </button>
      </div>
    </modal2>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import moment from 'moment';
import Modal2 from '@/components/Modal2';
import copy from 'clipboard-copy';
import Icon from '@/components/Icon';
import {debounce, isEqual, cloneDeep} from 'lodash';
import {Typeahead} from 'uiv';
import store from '@/store/index';
import BrokerAPI from '@/api/broker.js';
import StringHelper from '@/checkout/src/StringHelper'
import Datepicker from 'vuejs-datepicker';
import {de} from 'vuejs-datepicker/dist/locale';
import TelephoneForm from '../../components/shared/TelephoneForm.vue'
import EmailPreview from '@/components/EmailPreview.vue'
import ButtonComponent from '@/components/reusable/ButtonComponent'
import SwitchCheckbox from '@/checkout/src/components/shared/SwitchCheckbox.vue'
import NewFeature from '@/checkout/src/components/calculator-v2.0/parts/NewFeature.vue'
import LoaderComponent from '@/components/reusable/LoaderComponent'
import multiModules from '@/checkout/src/mixins/multiModules.js'
import computedInsurers from '@/checkout/src/mixins/computedInsurers.js'
import routeNavigationMixin from  '../../mixins/routeNavigationMixin.js'
import InsurerSorting from '@/checkout/api/insurer-sorting'

const STATE_NAMES = {
  open: 'Angebot erstellt',
  sent: 'Angebot versandt',
  accepted: 'Angebot akzeptiert',
  declined: 'Angebot abgelehnt',
  submitted: 'Antrag eingereicht'
};

export default {
  name: 'Offer',
  metaInfo: {
    title: 'Angebot',
    meta: [
      { name: 'description', content: 'CyberDirekt ist der Experte für die gewerbliche Cyberversicherung' }
    ]
  },
  components: {
    Icon,
    Modal2,
    Typeahead,
    Datepicker,
    TelephoneForm,
    EmailPreview,
    ButtonComponent,
    SwitchCheckbox,
    LoaderComponent,
    NewFeature
  },
  computed: {
    ...mapGetters([
      'filteredInsurers',
      'user',
      'userId',
      'offer',
      'insurersWithMultiModules',
      'getInsurersWithScoresSorted',
      'prices',
      'isOfferLoading'
    ]),
    insurerSelected() {
      // see if insurer is among the TR20 dynamic data - if it is not it means it is unreleased and therefore we should not show it
      // as a recommendation at all      
      if(this.offer.insurer){
        const isInsurerReleased = this.$store.state.checkout.application.insurers.find(i => i.key === this.offer.insurer)
        if(!isInsurerReleased) {
          return false
        }
      }

      // check if selection is accepted by agency (e.g. exclusive list)
      const isSelectionAccepted = this.insurers.findIndex((insurerItem) => insurerItem.key === this.offer.insurer) > -1

      return this.offer.insurer && this.offer.insurer !== 'no-recommendation' && isSelectionAccepted
    },
    isInsuranceLoading() {
      return this.isOfferLoading || this.isSomeOfInsuranceLoading
    },
    selectedModulesFiltered () {
      let filtered = {}

      if (!this.selectedInsurer) {
        return filtered
      } else {
        // go through all modules for given insurer, it is an array of objects
        const insurerModules = []
        const submoduleCategories = [] // here we store the submodules parents and we show them  instead of the submodules themselves
        this.selectedModules[this.selectedInsurer].forEach((prop) => {
          // find if the module is a submodule
          const match = this.insuranceProperties2_0_array.find(mod => mod.key === prop.key)

          if ((match && match.isSubmodule) || (match && match.parent === 'betriebsunterbrechung')) { // betriebsunterbrechung is a module group that is treated as a submodule group. Why not.
            const exists = submoduleCategories.find(cat => cat.parent === match.parent) // check if the parent already exists
            if (!exists) { // if it does not exist, we add it
              match.label = match.parentLabel
              submoduleCategories.push(match)
            }
          } else {
            insurerModules.push(prop)// it is a standard module, so we show it as it is
          }
        })
        // join two arrays
        const res = [...insurerModules, ...submoduleCategories]
        // assign to the computed result
        filtered[this.selectedInsurer] = res
        return filtered
      }
    },
    // insuranceproperties2_0 turned to an array
    insuranceProperties2_0_array() {
      const propObject = this.$store.state.checkout.application.insuranceProperties2_0 // using the updated TR20
      const properties =  [] // object to return so we dont trigger the recalculating of the computed property

      for (const group of propObject.groups) {
        if (!group.subgroups) { return }
        for (const subgroup of group.subgroups) {
          if (!subgroup.modules) { return }

          for (const mod of subgroup.modules) {
            if (mod.submodules) {
              for (const submod of mod.submodules) {
                submod.parent = mod.key // we need to identify the submodules later
                submod.parentLabel = mod.label // we need to identify the submodules later
                submod.isSubmodule = true // we need to identify the submodules later
                properties.push(submod)
              }
            } else {
              mod.parent = subgroup.key // we need to identify the submodules later
              mod.parentLabel = subgroup.label
              properties.push(mod)
            }
          }
        }
      }

      return properties
    },
    isInitLoaded() {
      return this.$store.state.checkout.application.isInitialDataLoaded;
    },
    insuredSelected() {
      return this.offer.insurer && this.offer.insurer !== 'no-recommendation';
    },
    paymentPeriod() {
      return {
        yearly: 'Jährlich',
        halfyearly: 'Halbjährlich',
        quarterly: 'Vierteljährlich',
        monthly: 'Monatlich'
      }[this.offer.paymentPeriod]
    },
    insurer() {
      const selectedInsurer = this.$store.state.checkout.application.insurers.find(insurer => insurer.key === this.offer.insurer);
      return selectedInsurer ? selectedInsurer.label : '';
    },
    insurerFull() {
      const selectedInsurer = this.$store.state.checkout.application.insurers.find(insurer => insurer.key === this.offer.insurer);
      return selectedInsurer || false
    },
    displayName() {
      return {
        firstname: (this.editMode) ? this.temporaryOfferData.firstname : this.offer.firstname,
        lastname: (this.editMode) ? this.temporaryOfferData.lastname : this.offer.lastname
      }
    },
    isEditable() {
      let lastLog;

      if (!this.offer.log || !this.offer.log.length) {
        lastLog = this.offer.state;
      } else {
        lastLog = this.offer.log[this.offer.log.length - 1].state;
      }

      return ['open', 'sent', 'accepted'].indexOf(lastLog) > -1 && (this.isBrokerChangeVisible || this.userId === this.offer.createdBy);
    },
    insuredSums() {
      return this.$store.getters.possibleInsuredSums2;
    },
    insurerPrices() {
      return this.$store.state.checkout.application.prices;
    },
    possibleDeductibles() {
      return this.$store.getters.possibleDeductibles2;
    },
    possiblePaymentPeriods() {
      return this.$store.getters.filteredPaymentPeriods
    },
    serviceProvider() {
      return this.$store.state.checkout.application.serviceProvider;
    },
    isAllianzInvalid() {
      let noCount = 0;

      // special check for allianz
      if (this.offer.insurer === 'allianz') {
        for (let questionNumber = 2; questionNumber <= 8; questionNumber++) {
          if (this.offer.answers[`q-allianz_${questionNumber}`] === 'no') {
            noCount++;
          }
        }
      }

      return noCount > 2;
    },
    isBrokerChangeVisible() {
      let user = this.$store.state.user;
      let offerVisibility = user.brokerAgency && user.brokerAgency.offerVisibility === 'all';

      return offerVisibility;
    },
    isAgencyChangeVisible() {
      let user = this.$store.state.user;
      let isAgencyChangeVisible = user.brokerAgency && user.brokerAgency.mainAgency;

      return isAgencyChangeVisible;
    },
    isAssignBackVisible() {
      const isTransferred = Array.isArray(this.offer.transferLog) && this.offer.transferLog.length > 0;
      const initialBrokerAgencyId = isTransferred ? this.offer.transferLog[this.offer.transferLog.length - 1].initialBrokerAgencyId : null;
      const offerAgency = this.offer.owner.brokerAgencyId;

      return isTransferred && initialBrokerAgencyId !== offerAgency && this.offer.createdBy === this.$store.state.user.id;
    },
    // this could be instead linked to getInsurersWithScoresSorted from store
    // TODO
    sortedSelection() {
      if (!this.offer.selection) {
        return []
      }

      let sortedToKeys = this.getInsurersWithScoresSorted.filter(i => (this.offer.selection.indexOf(i.insurer.key) > -1)).map(item => item.insurer.key)
      return sortedToKeys
    },
    pricePerformWinner() {
      return InsurerSorting.getSortedInsurers(this.filteredInsurers, 'price-score-ratio')[0]
    },
    showPricePerformWinner: {
      get() {
        if (this.offer.selection.includes(this.pricePerformWinner.key)) {
          return this.offer.showPricePerformWinner
        } else {
          return false
        }
      },
      set(value) {
        if (this.offer.selection.includes(this.pricePerformWinner.key)) {
          this.offer.showPricePerformWinner = value
        }
      }
    },

    reason: {
      get() {
        if (!this.insurerSelected) {
          return this.reasonNoRecommendation()
        } else {
          return this.offer.reason
        }
      },
      set(value) {
        this.offer.reason = value
      }
    },

    isOfferAccepted () {
      return this.offer.log.some(logEl => logEl.state === 'accepted')
    }
  },
  data() {
    return {
       emulateTouch: {
        offerPhone: false ,
      },
      de,
      editMode: false,
      insuranceEditMode: false,
      modulesEditMode: false,
      noteEditMode: false,
      brokerEditMode: false,
      selectedInsurer: null,
      selectedModules: {},
      riskQuestionEditMode: false,
      commentEditMode: false,
      additionalInsurers: [],
      temporaryOfferData: {
        // tracked fields
        id: '',
        company: '',
        salutation: '',
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        reason: '',
        comment: '',
        description: '',
        insurer: '',
        insuredSum: 0,
        insuredSumOption1: 0,
        insuredSumOption2: 0,
        deductible: 0,
        paymentPeriod: '',
        selectedModules: {},
        answers: {},
        selection: [],
        editReason: '',
        net: 0,
        gross: 0,
        createdBy: '',
        sortBy: null,
        showRating: undefined,
        showPricePerformWinner: undefined,
        form: {}
      },
      title: 'Angebot',
      filteredQuestions: [],
      sendModalOpen: false,
      sendModalByCyberOpen: false,
      riskQuestionModalOpen: false,
      riskQuestionModalHeading: 'Beantwortung der Risikofragen',
      riskQuestionModalText: '',
      riskQuestionModalFooter: true,
      email: '',
      timeline: [],
      selectedServiceProvider: null,
      serviceProviderString: '',
      formData: {
        name: '',
        street: '',
        city: '',
        country: ''
      },
      formSubsidiaryData: {
        country: '',
        revenue: ''
      },
      selectedAgency: null,
      selectedBroker: null,
      additionalInsurer: null,
      brokers: [],
      agencies: [],
      disabledFormDates: {
        to: moment()
          .startOf('day')
          .toDate()
      },
      isSelectionLoading: true,
      isSelectionSorted: false,
      paymentPeriods: [
        {
          value: 'yearly',
          label: 'Jährlich'
        },
        {
          value: 'halfyearly',
          label: 'Halbjährlich'
        },
        {
          value: 'quarterly',
          label: 'Vierteljährlich'
        },
        {
          value: 'monthly',
          label: 'Monatlich'
        }
      ],
      isSomeOfInsuranceLoading: false
    }
  },
  created() {
    this.calcPrice();
  },
  mounted() {
    this.$store.commit('RESET_INPUTS_WITH_MAP', {
      answers: this.offer.answers
    })

    this.offer.answers = this.offer.answers || {};
    this.offer.note = this.offer.note || '';
    this.offer.editReason = this.offer.editReason || '';
    this.offer.phone = this.offer ? this.offer.phone : '';

    // update title
    this.title = `Angebot: ${this.offer.firstname} ${this.offer.lastname}`;
    this.$emit('updateHead');

    // .. remove?
    this.$store.commit('SET_SORTING_LOGIC', this.offer.sortBy || 'abc')
    this.$store.dispatch('CALCULATE_SCORES', this.prices); // using the existing prices from vuex for score calculations
    // this.calcInsurersWithScores()

    this.email = this.offer.email;
    this.selectedInsurer = this.offer.insurer;
    this.getQuestions();
    this.getModules();

    // build timeline
    ['open', 'sent', 'accepted', 'submitted'].forEach(stateName => {
      let state = this.getLogItem(stateName);
      let item;

      item = {
        state: STATE_NAMES[stateName],
        done: !!state
      };

      if (state) {
        item.date = state.timestamp;
      }

      if (!state && stateName === 'open') {
        this.timeline.push({
          state: STATE_NAMES['open'],
          done: true,
          date: this.offer.created
        });
      } else {
        this.timeline.push(item);
      }

      // set previous steps to done if maybe one or more steps was skipped
      if (state && !!state && this.timeline.length > 1) {
        this.timeline[this.timeline.length - 2].done = true;
      }
    });

    if (this.isBrokerChangeVisible) {
      BrokerAPI
        .getAgencyBrokers()
        .then((response) => {
          this.agencies = response.data;

          const selectedAgency = this.agencies.find((agency) => {
            return agency.brokers.find(broker => broker.id === this.offer.createdBy)
          });

          if (selectedAgency) {
            this.selectedAgency = selectedAgency.id;
            this.selectedBroker = this.offer.createdBy;
          } else {
            this.selectedAgency = this.agencies[0].id;
            this.selectedBroker = this.selectedAgency.brokers[0].id;
          }
        });
    }

    store.commit('SET_INIT_STATUS', null)
  },
  head: {
    title: function () {
      return {
        inner: this.title
      }
    }
  },
  watch: {
    'sortedSelection'() {
      this.sortedSelection.length === this.offer.selection.length ? this.isSelectionSorted = true : ''
    },
    'offer.insuredSum'() {
      this.updateStore();
      const self = this

      this.isSomeOfInsuranceLoading = true

      this.$store.dispatch('CALCULATE_CYBER_PRICES').then(() => {
        self.calcPrice().then(() => {          
          this.isSomeOfInsuranceLoading = false
        })
      })
    },
    async 'offer.insuredSumOption1'() {
      this.updateStore();
    },
    async 'offer.insuredSumOption2'() {
      this.updateStore();
    },
    'offer.insurer'(value) {
      this.deselectInsuredSumOptions()
      this.selectedInsurer = value
      this.updateStore();
      this.calcPrice();
      this.getQuestions();
      this.getModules();
    },
    'offer.deductible'() {
      this.updateStore();
      const self = this

      this.isSomeOfInsuranceLoading = true

      this.$store.dispatch('CALCULATE_CYBER_PRICES').then(() => {
        self.calcPrice().then(() => {          
          this.isSomeOfInsuranceLoading = false
        })
      })
    },
    'offer.sortBy'() {
      this.$store.commit('SET_SORTING_LOGIC', this.offer.sortBy || 'abc')
      this.$store.dispatch('CALCULATE_SCORES', this.prices); // using the existing prices from vuex for score calculations
    },
    'offer.selection'(selection) {
      if (!Array.isArray(selection)) {
        // Somehow, when you cancel editing, "offer.selection" becomes an Object type with index keys
        // To avoid unexpected behavior, I have to transform it into an Array like this:
        this.offer.selection = Object.keys(this.offer.selection).map(key => this.offer.selection[key])
      }
    },
    'offer.paymentPeriod'(newValue) {
      const self = this

      this.isSomeOfInsuranceLoading = true

      this.$store.commit('SET_PAYMENT_PERIOD', newValue)
      this.$store.dispatch('CALCULATE_CYBER_PRICES').then(() => {
        self.calcPrice().then(() => {          
          this.isSomeOfInsuranceLoading = false
        })
      })
    },
    selectedAgency() {
      const agency = this.agencies.find(a => a.id === this.selectedAgency);
      const selectedBroker = agency.brokers.find(broker => broker.id === this.offer.createdBy);

      this.brokers = agency.brokers;

      if (selectedBroker) {
        this.selectedBroker = selectedBroker.id;
      } else {
        this.selectedBroker = this.brokers[0].id;
      }
    },
    isInitLoaded(a, b) {
      if(this.offer.insurer && this.offer.insurer !== 'no-recommendation') {
      // see if insurer has representation in prices elements - if not, it means it is not released or active
      // or was deleted or whatever, it simply isnt there and cant be shown.
      const isInsurerActive = this.insurerPrices[this.offer.insurer]

      if(!isInsurerActive) {
        this.$Simplert.open({
            type: 'warning',
            title: `Versicherers nicht mehr gültig!`,
            message: `Leider ist der Tarif des empfohlenen Versicherers nicht mehr gültig. Sie können die Empfehlung unter "Versicherung" ganz einfach anpassen. Bei Fragen steht Ihnen das Team vom Maklervertrieb gerne zur Verfügung.`,
            customCloseBtnText: 'OK',
            customCloseBtnClass: 'btn btn-primary ',
          })
        }
      }
    },
    insurers: {
      deep: true,
      handler() {
        // here too refresh sorting calculations!
        this.$store.commit('SET_SORTING_LOGIC', this.offer.sortBy || 'abc')
        this.$store.dispatch('CALCULATE_SCORES', this.prices); // using the existing prices from vuex for score calculations
        // this.calcInsurersWithScores()
      }
    },
    isSelectionSorted(isSorted) {
      this.isSelectionLoading = !isSorted
    }
  },
  async beforeRouteEnter(to, from, next) {
    if(!store.state.checkout.application.isInitialDataLoaded) {
      store.commit('SET_APPLICATION_DATA', {
        insurers: [],
        questions: {},
        insurersWithScores: []
      })
      await store.dispatch('GET_INITIAL_DATA')
    }
    store.dispatch('GET_OFFERS', to.params.offerId).then(() => {
        next((vm) => {
          vm.handleRouteNavigation(next, vm) // in mixin
        })
      }).catch(err => {
        console.log('beforeRouteEnter error:', err)
        store.commit('SET_INIT_STATUS', null)
      })
  },
  beforeRouteUpdate(to, from, next) {
    store
      .dispatch('GET_OFFERS', to.params.offerId)
      .then(() => {
        next(vm => {
          vm.handleRouteNavigation(next, vm) // in mixin
        });
      });
  },
  methods: {    
    reasonNoRecommendation() {
      return StringHelper.get(
        this.$store,
        'OFFER_REASON_NO_RECOMMENDATION',
        true
      )
    },

    commentNoRecommendation() {
      return StringHelper.get(
        this.$store,
        'OFFER_COMMENT_NO_RECOMMENDATION',
        true
      )
    },

    updatePhoneNumber (res) {
        this.offer.phone = res.number;
    },
    getForm(question) {
      if (question.form && question.form.fields) {
        return question.form.fields;
      } else if (question.form && question.form[this.offer.answers[`q-${question.id}`]]) {
        return question.form[this.offer.answers[`q-${question.id}`]];
      }
    },
    getQuestions() {
      this.filteredQuestions = this.$store.getters.filteredQuestions();
      this.filteredQuestions.forEach((question) => {
        // auto answer question with only one answer
        if (question.answers && question.answers.length === 1) {
          this.$set(this.offer.answers, `q-${question.id}`, question.answers[0].value);
        }

        if (question.form) {
          this.offer.form = this.offer.form || {};

          if (!this.offer.form[question.id]) {
            this.$set(this.offer.form, question.id, {});
          }

          Object.keys(question.form).forEach((answer) => {
            if (answer === 'style') return;
            const fields = question.form[answer];

            if (fields && fields.length) {
              fields.forEach((field) => {
                if (!this.offer.form[question.id][field.name]) {
                  switch (field.type) {
                    case 'address-repeater':
                    case 'subsidiary-repeater':
                      this.$set(this.offer.form[question.id], field.name, []);
                      break;
                    default:
                      this.$set(this.offer.form[question.id], field.name, undefined);
                      break;
                  }
                }
              });
            }
          })
        }
      });
    },
    getModules() {
      this.$store.state.checkout.application.insurers.forEach(insurer => {
        this.selectedModules[insurer.key] = [];

        for (let propertyKey in insurer.properties) {
          if (insurer.properties.hasOwnProperty(propertyKey) && ['optional-preselected', 'optional'].indexOf(insurer.properties[propertyKey].state) > -1) {

            let propertyInfos = this.insuranceProperties2_0_array.find(property => property.key === propertyKey);
            if (propertyInfos) {
              if (this.offer.selectedModules[insurer.key] &&
                this.offer.selectedModules[insurer.key].length &&
                insurer.key === this.selectedInsurer) {
                  this.selectedModules[insurer.key].push({
                    key: propertyKey,
                    label: propertyInfos.label,
                    parent: propertyInfos.parent || false
                  });
                } else {
                  this.selectedModules[insurer.key].push({
                    key: propertyKey,
                    label: propertyInfos.label,
                    parent: propertyInfos.parent || false
                });
              }
            }
          }
        }
      });
    },
    removeSelectedInsurer(selectedInsurerKey) {
      this.offer.selection = this.offer.selection.filter(i => i !== selectedInsurerKey);

      if (this.offer.insurer === selectedInsurerKey) {
        this.offer.insurer = 'no-recommendation';
      }
      this.additionalInsurers = this.filteredInsurers.filter(insurer => this.offer.selection.indexOf(insurer.key) === -1)
    },
    addAdditionalInsurer() {
      this.offer.selection.push(this.additionalInsurer);

      const selectedModulesKeys = this.selectedModules[this.additionalInsurer].map(el => el.key)

      this.offer.selectedModules[this.additionalInsurer] = selectedModulesKeys

      this.additionalInsurer = null;
      this.additionalInsurers = this.filteredInsurers.filter(insurer => this.offer.selection.indexOf(insurer.key) === -1)
    },
    getInsurerName(insurerKey) {
      const insurer = this.filteredInsurers.find(i => i.key === insurerKey);
      return insurer ? insurer.label : '';
    },

    optionalChanged(propertyKey) {
      // console.log('optionalChanged', propertyKey)

      let multiModuleMap = this.insurersWithMultiModules; // is an array with objects defining which modules are grouped together
      let groupModuleName = '';
      let insurer = this.offer.insurer;
      // console.log('multiModuleMap', multiModuleMap)

      // check if this is a submodule?
      if (multiModuleMap[insurer]) {
        groupModuleName = this.findMatchingModel(this.insurerPrices[insurer], multiModuleMap[insurer], propertyKey)

        if (groupModuleName) {
          if (this.offer.selectedModules[insurer].indexOf(propertyKey) === -1) {
            // turn all group modules off
            this.insurerPrices[insurer].modules[groupModuleName].modules.forEach((subModule) => {
              const moduleIndex = this.offer.selectedModules[insurer].indexOf(subModule);

              if (moduleIndex > -1) {
                this.offer.selectedModules[insurer].splice(moduleIndex, 1);
              }
            });
          } else {
            // turn all group modules on
            this.insurerPrices[insurer].modules[groupModuleName].modules.forEach((subModule) => {
              if (this.offer.selectedModules[insurer].indexOf(subModule) === -1) {
                this.offer.selectedModules[insurer].push(subModule);
              }
            });
          }
        }
      }

      this.updateStore();
      this.calcPrice();
    },
    debounceInput: debounce(function () {
      this.$store.dispatch('PATCH_OFFERS', this.offer)
        .then(() => {
          this.showToast();
        });
    }, 2000),
    showToast() {
      this.$toasted.show('Angebot geändert', {
        theme: 'bubble',
        type:'success',
        position: 'bottom-left',
        duration: 4000
      });
    },
    getIconName(answers, questionKey) {
      return this.offer[answers] && this.offer[answers][questionKey] === 'yes' ? 'check' : 'cross';
    },
    getIconColor(answers, questionKey) {
      return this.offer[answers] && this.offer[answers][questionKey] === 'yes' ? 'primary' : 'secondary';
    },
    getAnswer(answers, question) {
      const questionKey = `q-${question.id}`;
      const i18n = {
        yes: 'Ja',
        no: 'Nein'
      };

      if (answers && answers[questionKey]) {
        if (i18n[answers[questionKey]]) {
          return i18n[answers[questionKey]];
        } else if (question.answers) {
          // find label
          if (question.answers) {
            let answer = question.answers.find(answer => answer.value === answers[questionKey]);

            if (answer) {
              return answer.label;
            }
          }
        }
      }

      return '';
    },
    isHardKnockout(question) {
      if (!question.strict) {
        return
      } else {
        let answer = this.offer.answers[`q-${question.id}`];
        return answer !== question.expect
      }
    },
    isAnswerValid(question) {
      let answer = this.offer.answers[`q-${question.id}`];

      if (this.offer.insurer === 'allianz' &&
        !question.expect &&
        this.isAllianzInvalid &&
        question.index >= 2 && question.index <= 8) {
        return answer !== 'no';
      } else if (question.form || !question.expect || !answer) {
        return true;
      } else if (Array.isArray(question.expect)) {
        return question.expect.indexOf(answer) > -1;
      }

      return answer === question.expect;
    },
    takeMeBack() {
      this.$router.push({name: 'Angebote'});
    },
    copy() {
      copy(this.offer.link);
      this.$toasted.show('Link wurde kopiert.', {
        theme: 'bubble',
        type:'success',
        position: 'bottom-left',
        duration: 4000
      });
    },
    displayDate(checkoutObj) {
      const date = moment(checkoutObj.createdAt);
      return date.format('DD.MM.YYYY, HH:mm');
    },
    getSalutation() {
      const lastname = this.offer.lastname || 'Mustermann';
      const salutation = this.offer.salutation || 'Herr';

      if (this.offer.salutationOverwrite) {
        return this.offer.salutationOverwrite;
      } else {
        if (!this.offer.lastname) {
          return `Sehr geehrte Damen und Herren,`;
        } else if (salutation === 'Herr') {
          return `Sehr geehrter Herr ${lastname},`;
        } else {
          return `Sehr geehrte Frau ${lastname},`;
        }
      }
    },
    getState() {
      let lastLog;

      if (!this.offer.log || !this.offer.log.length) {
        lastLog = this.offer.state;
      } else {
        lastLog = this.offer.log[this.offer.log.length - 1].state;
      }

      lastLog = lastLog || 'open';

      return STATE_NAMES[lastLog];
    },
    send() {
      // this.sendModalOpen = false;
      this.sendModalByCyberOpen = false;

      this.$toasted.show('E-Mail wurde versandt.', {
        theme: 'bubble',
        type:'success',
        position: 'bottom-left',
        duration: 4000
      });
      this.$validator
        .validateAll()
        .then((isValid) => {
          if (isValid) {
            this.$store
              .dispatch('SEND_OFFER', {customerEmail: this.email, ccEmail: this.user.email, offerId: this.offer.id, action: 'offerUrl'});
          }
        });
    },
    memStoreOffer() {
      // Let's save offer data in case the user cancels
      Object.keys(this.temporaryOfferData)
        .forEach(key => {
          if (this.offer[key] !== null && Array.isArray(this.offer[key])) {
            this.temporaryOfferData[key] = [...this.offer[key]]
          } else if (this.offer[key] !== null && typeof this.offer[key] === 'object') {
            this.temporaryOfferData[key] = cloneDeep(this.offer[key]);
          } else {
            this.temporaryOfferData[key] = this.offer[key];
          }
        });
    },
    checkOfferDiff(tempData, originData) {
      const keys = Object.keys(tempData)
      let isDiff = false

      for (let i = 0; i < keys.length; i++) {
  
        if (typeof tempData[keys[i]] === 'object') {
          isDiff = !isEqual(tempData[keys[i]], originData[keys[i]])
        } else {
          isDiff = tempData[keys[i]] !== originData[keys[i]]
        }

        if (isDiff) {
          break
        }
      }

      return isDiff
    },
    editRiskQuestion() {
      this.riskQuestionEditMode = true;
      this.memStoreOffer()
    },
    editCustomerInfo() {
      this.editMode = true;
      this.memStoreOffer()
    },
    editComment() {
      this.commentEditMode = true;
      this.memStoreOffer()
    },
    editNote() {
      this.noteEditMode = true;
      this.memStoreOffer()
    },
    editBroker() {
      this.brokerEditMode = true;
      this.memStoreOffer()
    },
    editInsuranceInfo() {
      this.updateStore();
      this.insuranceEditMode = true;
      this.memStoreOffer()
    },
    editModulesInfo() {
      this.updateStore();
      this.modulesEditMode = true;
      this.memStoreOffer()
    },
    assignOfferBack() {
      this.$store
        .dispatch('ASSIGN_BACK_ENTERPRISE_OFFER', {offerId: this.offer.id})
        .then(() => {
          this.brokerEditMode = false;
          this.showToast();
          this.takeMeBack();
        });
    },
    saveBroker() {
      const isDiff = 
        this.temporaryOfferData.createdBy && this.selectedBroker
        && this.temporaryOfferData.createdBy !== this.selectedBroker

      this.brokerEditMode = false;
      if (this.isBrokerChangeVisible && isDiff) {
        if (this.isAgencyChangeVisible) {
          this.$store
            .dispatch('REASSIGN_ENTERPRISE_OFFER', {offerId: this.offer.id, brokerId: this.selectedBroker})
            .then(() => {
              this.showToast();
            });
        } else {
          this.$store.dispatch('CHANGE_OFFER_OWNER', {offerId: this.offer.id, createdBy: this.selectedBroker})
            .then(() => {
              this.showToast();
            });
        }
      }
    },
    saveCustomerInfo() {
      if (this.offer.insurer !== this.temporaryOfferData.insurer) {
        if (this.offer.insurer === 'no-recommendation') {
          this.offer.reason = StringHelper.get(this.$store, 'OFFER_REASON_NO_RECOMMENDATION', true);
          this.offer.comment = StringHelper.get(this.$store, 'OFFER_COMMENT_NO_RECOMMENDATION', true);
        } else {
          this.offer.reason = StringHelper.get(this.$store, 'OFFER_REASON', true, {insurer: this.getInsurerName(this.offer.insurer)});
          this.offer.comment = StringHelper.get(this.$store, 'OFFER_COMMENT', true);
        }
      }

      // looks like it shouldn't be executed, so we can compare if changes were made or not
      // [delete] this.memStoreOffer();

      this.editMode = false;
      this.insuranceEditMode = false;
      this.modulesEditMode = false;
      this.noteEditMode = false;
      this.commentEditMode = false;

      const isDiff = this.checkOfferDiff(this.temporaryOfferData, this.offer)

      if (isDiff) {

        // because there is an issue with the Lodash.merge function, we have to manually add 
        // insuredSumOption! and 2 with null, if they arent present in the update. Otherwise 
        // the Patch request wouldnt remove them from the object
        if (!this.offer.insuredSumOption1) {
          this.offer.insuredSumOption1 = null
        }
        if (!this.offer.insuredSumOption2) {
          this.offer.insuredSumOption2 = null
        }

        this.$store.commit('SET_FULL_SCREEN_LOADER', true)

        this.$store.dispatch('PATCH_OFFERS', this.offer)
          .then(() => {
            return this.calcPrice();
          })
          .then(() => {
            this.showToast();
            this.$store.commit('SET_FULL_SCREEN_LOADER', false)
          });
      }
      
    },
    saveModuleInfo() {
      this.editMode = false;
      this.insuranceEditMode = false;
      this.modulesEditMode = false;
      const self = this
      
      const isDiff = this.checkOfferDiff(this.temporaryOfferData, this.offer)

      if (isDiff) {
        this.isSomeOfInsuranceLoading = true

        const promise1 = this.$store.dispatch('PATCH_OFFERS', {id: this.offer.id, selectedModules: this.offer.selectedModules})

        const promise2 = new Promise((resolve) => {
          this.$store.dispatch('CALCULATE_CYBER_PRICES').then(() => {
            self.calcPrice().then(() => {
              resolve()
            })
          })
        })

        Promise.all([promise1, promise2]).then(() => {
          this.isSomeOfInsuranceLoading = false
          this.showToast()
        })
      }

    },
    cancelEditMode() {
      this.editMode = false;
      this.insuranceEditMode = false;
      this.modulesEditMode = false;
      this.noteEditMode = false;
      this.riskQuestionEditMode = false;
      this.commentEditMode = false;
      this.brokerEditMode = false;

      Object.keys(this.temporaryOfferData)
        .forEach(key => {
          if (key !== 'id') {
            if (this.temporaryOfferData[key] !== null && typeof this.temporaryOfferData[key] === 'object') {
              this.offer[key] = {...this.temporaryOfferData[key]};
            } else {
              this.offer[key] = this.temporaryOfferData[key];
            }
          }
        })
    },
    saveRiskQuestions() {
      let difference = {};
      let note = '';
      let noteShort = '';
      let self = this;
      const ANSWERS = {
        yes: 'Ja',
        no: 'Nein'
      };
      this.riskQuestionEditMode = false;
      let isModuleSelectionChanged = false

      // get diff
      Object.keys(this.offer.answers).forEach((questionKey) => {
        if (this.temporaryOfferData.answers[questionKey] !== this.offer.answers[questionKey]) {
          difference[questionKey] = this.offer.answers[questionKey];

          const question = this.filteredQuestions?.find(el => 'q-' + el.id === questionKey)
          const answer = this.offer.answers[questionKey]
          if (question.deselectModule && question?.deselectModule?.[answer]?.length) {
            this.isSomeOfInsuranceLoading = true

            question.deselectModule[answer].forEach((module) => {
              this.deselectModule(this.offer.insurer, module)
            });
            isModuleSelectionChanged = true
          }
        }
      });

      const isAnswersDiff = Object.keys(difference).length > 0
      const isFormDiff = !isEqual(this.temporaryOfferData.form, this.offer.form)

      this.riskQuestionEditMode = false;

      Object.keys(difference).forEach((key) => {
        let question = this.filteredQuestions.find(question => key === 'q-' + question.id);
        const salutation = this.user.salutation === 'Herr' ? 'Herrn' : 'Frau';

        if (difference[key] && ['yes', 'no'].indexOf(difference[key]) > -1) {
          note += `Antwort der Risikofrage “${question.title}“ wurde von ${salutation} ${this.user.firstname} ${this.user.lastname} am ${moment().format('DD.MM.YYYY')} um ${moment().format('HH:mm')} Uhr auf ${ANSWERS[difference[key]]} geändert.\n\n`;
          noteShort += `Antwort der Risikofrage “${question.title}“ wurde von ${salutation} ${this.user.firstname} ${this.user.lastname} am ${moment().format('DD.MM.YYYY')} um ${moment().format('HH:mm')} Uhr auf ${ANSWERS[difference[key]]} geändert weil: \n\n`;
        }
      });

      if (this.offer.note.length > 0) {
        this.offer.note += '\n';
      }

      if (this.offer.comment.length > 0) {
        this.offer.editReason += '\n';
      }

      this.offer.note += note;
      this.offer.editReason += noteShort;

      this.updateStore();

      if (isModuleSelectionChanged) {
        this.$store.dispatch('CALCULATE_CYBER_PRICES').then(() => {
          self.calcPrice().then(() => {
            this.isSomeOfInsuranceLoading = false
          })
        })
      }

      if (isAnswersDiff || isFormDiff) {
        this.isSomeOfInsuranceLoading = true
        this.$store.dispatch('CALCULATE_CYBER_PRICES', { withAnswers: true }).then(() => {
          self
          .calcPrice()
          .then(() => {
            this.isSomeOfInsuranceLoading = false
            return this.$store.dispatch('PATCH_OFFERS', this.offer)
          })
          .then(() => {
            this.$toasted.show('Bitte begründen Sie die Änderungen der Antworten im Feld "Kommentar zum Antrag".', {
              theme: 'bubble',
              position: 'bottom-left',
              duration: 10000
            });
            this.commentEditMode = true;
            this.$nextTick(() => {
              if (self.$refs.editReasonField) {
                self.$refs.editReasonField.focus();
              }
            });
          });
        })
      }
    },
    deselectModule(insurer, module) {
      const moduleIndex = this.offer.selectedModules[insurer].indexOf(module);

      if (moduleIndex > -1) {
        this.offer.selectedModules[insurer].splice(moduleIndex, 1);
      }
    },
    open() {
      window.open(this.offer.link, '_blank');
    },
    getLogItem(stateName) {
      return this.offer.log ? this.offer.log.find(item => item.state === stateName) : undefined;
    },
    calcPrice() {
      return new Promise((resolve) => {
        const prices = this.$store.state.checkout.application.cyberPrices

        const insurerKey = this.offer.insurer;
        const insurerPrices = prices[insurerKey];

        if (insurerPrices && this.offer.style !== 'pma-2018') {
          this.offer.net = insurerPrices.net;
          this.offer.gross = insurerPrices.gross;
        }

        // filter insurer selection by price
        if (Object.keys(prices).indexOf(insurerKey) === -1) {
          this.offer.insurer = 'no-recommendation';
        }

        this.offer.selection = this.offer.selection
          .filter((insurer) => {
            const insurerKey = insurer;
            const isInPrices = Object.keys(prices).indexOf(insurerKey) > -1;
            const isInFilteredInsurers = this.filteredInsurers.find(filteredInsurer => filteredInsurer.key === insurer);

            return isInPrices && isInFilteredInsurers;
          })
          .sort();

        this.additionalInsurers = this.filteredInsurers.filter((insurer) => {
          let insurerKey = insurer.key;

          return this.offer.selection.indexOf(insurer.key) === -1 &&
            Object.keys(prices).indexOf(insurerKey) > -1
        });
        resolve()
      })
    },
    deselectInsuredSumOptions() {
      delete this.offer.insuredSumOption1
      delete this.offer.insuredSumOption2
    },
    updateStore() {
      this.$store.dispatch('UPDATE_INPUT_VALUES', {
        netTurnOver: this.offer.netTurnOver,
        insurer: this.offer.insurer,
        selectedModules: this.offer.selectedModules,
        insuredSum: this.offer.insuredSum,
        insuredSumOption1: this.offer.insuredSumOption1,
        insuredSumOption2: this.offer.insuredSumOption2,
        deductible: this.offer.deductible,
        paymentPeriod: this.offer.paymentPeriod,
        answers: this.offer.answers,
        form: this.offer.form,
        answersOnline: this.offer.answersOnline,
        type: 'contract',
        industry: this.offer.industry,
        email: this.offer.email || '',
        phone: this.offer.phone || '',
        company: this.offer.company || '',
        salutation: this.offer.salutation || '',
        title: this.offer.title || '',
        firstname: this.offer.firstname || '',
        lastname: this.offer.lastname || '',
        street: this.offer.street || '',
        postal: this.offer.postal || '',
        city: this.offer.city || '',
        dueDate: this.offer.dueDate || false,
        billingDate: this.offer.billingDate || moment().add(1, 'year').startOf('day').toDate(),
        startDate: this.offer.startDate ? new Date(this.offer.startDate) : new Date(),
        paymentMethod: this.offer.paymentMethod || 'invoice',
        newsletterCheck: false,
        checkFinalDeclaration: false,
        checkRevocation: false,
        checkContractDocuments: false,
        checkBrokerTerms: false,
        checkVVG: false,
        offer: this.offer.id,
        note: this.offer.note,
        editReason: this.offer.editReason,
        sepa: this.offer.sepa || {
          iban: '',
          firstname: '',
          lastname: '',
          company: '',
          street: '',
          postal: '',
          city: ''
        }
      });
      // workaround to update selected modules (regular UPDATE_INPUT_VALUES is merging instead of reassigning selectedModules)
      this.$store.commit('SET_SELECTED_MODULES', this.offer.selectedModules)

    },
    isSubquestion(question) {
      return question.conditions &&  question.conditions.length
        && question.conditions.some(cond => cond.property.startsWith('answers.'))
    },
    sendCheckout() {
      let activeStep;
      // clear inputs
      this.updateStore();
      activeStep = this.$store.state.checkout.application.steps.find((step) => step.label === 'Vertragsdaten');
      this.$store.commit('SET_ACTIVE_STEP', activeStep);
      this.$router.push({path: '/versicherung/5/vertragsdaten'});
    },
    checkout() {
      let allAnswered = false;
      let allValid = true;
      let hardKnockout = false

      if (this.offer.answers) {
        allAnswered = this.filteredQuestions.every(question => {
          const boo = Object.keys(this.offer.answers).includes('q-' + question.id)
          if (!boo) {
            if (this.offer.form) {
              return Object.keys(this.offer.form).includes(question.id)
            }
          }
          return boo
        })

        //check if strict question were answered wrong
        hardKnockout = this.filteredQuestions.some((question) => {
          return this.isHardKnockout(question);
        });

        // all questions are valid answered?
        allValid = this.filteredQuestions.every((question) => {
          return this.isAnswerValid(question);
        });
      }
      if (!allAnswered) {
        this.$toasted.show('Die Risikofragen wurden unvollständig beantwortet.', {
          theme: 'bubble',
          position: 'bottom-left',
          duration: 4000
        });
      } else if (hardKnockout) {
        this.riskQuestionModalOpen = true;
        this.riskQuestionModalFooter = false;
        this.riskQuestionModalHeading = 'Antragseinreichung nicht möglich';
        this.riskQuestionModalText = 'Aufgrund der Beantwortung der Risikofragen ist eine Einreichung des Antrags nicht möglich. Kontaktieren Sie uns gerne unter 03040369529, um eine individuelle Lösung zu finden.';
      } else if (this.isAllianzInvalid) {
        this.riskQuestionModalOpen = true;
        this.riskQuestionModalText = 'Von den Risikofragen 2 bis 8 wurden mehr als 2 mit Nein beantwortet. Aufgrund dessen muss eine individuelle Anfrage beim Versicherer gestellt werden. Sie können den Antrag trotzdem einreichen.';
      } else if (!allValid) {
        this.riskQuestionModalOpen = true;
        this.riskQuestionModalText = 'Mit diesen Angaben erfüllen Sie nicht die IT-Mindestanforderungen des Versicherers. Der Antrag kann trotzdem eingereicht werden und wird vom Versicherer individuell geprüft.';
      } else {
        this.riskQuestionEditMode = true;
        this.$nextTick(() => {
          this.$validator
            .validateAll()
            .then((result) => {
              if (result) {
                this.sendCheckout();
              } else {
                this.$toasted.show('Nicht alle Felder in den Risikofragen wurden ausgefüllt.', {
                  theme: 'bubble',
                  position: 'bottom-left',
                  duration: 4000
                });
              }
            });
        });
      }
    },
    getFieldClass(fieldName) {
      if (!this.fields[fieldName]) {
        return;
      }

      return {
        'is-danger': this.errors.has(fieldName),
        'touched': this.fields[fieldName].dirty
      }
    },
    getAnswers(question) {
      return question.answers ? question.answers : [
        {label: 'Ja', value: 'yes'},
        {label: 'Nein', value: 'no'}
      ];
    },
    spSelected() {
      if (this.selectedServiceProvider) {
        this.formData = this.selectedServiceProvider;
      }
    },
    removeServiceProvider(questionId, fieldName, index) {
      this.$delete(this.offer.form[questionId][fieldName], index);
    },
    addServiceProvider(questionId, fieldName) {
      let {street, city, country} = this.formData;

      if (typeof this.selectedServiceProvider === 'string') {
        this.offer.form[questionId][fieldName].push({
          name: this.selectedServiceProvider,
          street,
          city,
          country
        });
      } else {
        this.offer.form[questionId][fieldName].push({
          name: this.selectedServiceProvider.name,
          street,
          city,
          country
        });
      }

      this.formData = {
        name: '',
        street: '',
        city: '',
        country: ''
      };
      this.serviceProviderString = '';
      this.selectedServiceProvider = null;
    },
    addSubsidiary(questionId, fieldName) {
      let {country, revenue} = this.formSubsidiaryData;

      this.offer.form[questionId][fieldName].push({
        country,
        revenue
      });

      this.formSubsidiaryData = {
        country: '',
        revenue: ''
      };
    },
    handleRiskAnswerChange(value, key) {
      if (!value) {
        delete this.offer.answers[key]
      }
      this.$store.commit('SET_ANSWERS', this.offer.answers)
      this.getQuestions()
    },
    isAvailable(insurer, key) {
      if (insurer && insurer.properties && insurer.properties[key]) {
        return (
          insurer.properties[key].state === 'available' &&
          !insurer.properties[key].disabled
        )
      } else {
        return false
      }
    },
    isOptional(insurer, key) {
      if (insurer && insurer.properties && insurer.properties[key]) {
        return (
          (insurer.properties[key].state === 'optional' ||
            insurer.properties[key].state === 'optional-preselected') &&
          !insurer.properties[key].disabled
        )
      } else {
        return false
      }
    },
    isSelected(insurer, key) {
      const selectedModules = this.offer.selectedModules || this.inputs.selectedModules
      return selectedModules[insurer.key] && selectedModules[insurer.key].includes(key)
    }
  },
  // mixins: [multiModules, computedInsurers, calcInsurersScores, routeNavigationMixin]
  mixins: [multiModules, computedInsurers, routeNavigationMixin]
}
</script>

<style lang="scss" scoped>
@import '../../scss/variables';
@import '../../scss/form';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

h1 {
  @include media-breakpoint-down(sm) {
    word-break: break-word;
  }
}
.is-data-loading {
  tr:not(.loading-anim) {
    display: none;
  }
}

.dropdown-menu {
  right: 0;
}

.footer-btns {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.dropdown {
  &.open {
    .dropdown-menu {
      display: block;
    }
  }
}

.toolbar {
  flex-wrap: wrap;
  gap: 0.5rem;

  .btn-wrap .btn {
    width: 100%;
  }

  .btn, .btn-wrap {
    width: auto;

    @include media-breakpoint-up(lg) {
      flex-grow: 1;
    }
  }

  @include media-breakpoint-up(xl) {
    .btn, .btn-wrap {
      flex: 1;
    }
  }
}

.control-cell {
  .custom-control {
    width: 20px;
    margin: 0;
  }
}

.btn {
  border: 0;
}

.btn-icon {
  border: 0;
  background: none;
  cursor: pointer;
}

.question-answer {
  position: relative;
}

.question-error {
  position: absolute;
  right: -5px;
  top: 0;
}


.table-wrapper {
  @include media-breakpoint-down(sm) {
    overflow: auto;
  }
}

.table {
  thead {
    tr {
      th {
        height: 47px;
        padding: 0.5rem;
      }
    }
  }

  tr.additional-option {
    td {
      border-top: 0;
    }

    .additional-option__label {
      padding-left: 2rem;
    }
  }
}

.editable-table {
  thead {
    th.controls {
      text-align: right;
      width: 95px;

      span {
        float: right;
      }

      button {
        border-radius: 3px;
        border-color: #cacaca;
      }

      &--disable {
        button {
          display: none;
        }
      }
    }
  }

  tbody {
    tr {
      td:first-child {
        width: 108px;
      }
      td:nth-child(2) {
        width: 100%;
      }
    }
  }

  .form-control {
    margin: 0;
  }

  textarea {
    height: 150px;
  }
}

.timeline {
  @include media-breakpoint-down(md) {
    display: flex;
    margin-bottom: 1rem;
  }

  &__item {
    position: relative;
    width: 100%;
    height: 80px;
    margin-bottom: 24px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #E8EBEE;

    @include media-breakpoint-down(md) {
      display: flex;
      margin-bottom: 0;

      & + & {
        margin-left: 1rem;
      }
    }

    @include media-breakpoint-down(sm) {
      & + & {
        margin-left: 0.5rem;
      }
    }

    &__text {
      font-size: .9rem;
      font-weight: bold;
      opacity: .5;

      @include media-breakpoint-down(sm) {
        text-align: center;
        font-size: 0.75rem;
      }
    }

    &__date {
      font-size: .8rem;
    }

    &--done {
      color: $blueTone;
      background: $lightBlueTone;

      .timeline__item__text {
        text-align: center;
        opacity: 1;
      }

      &:before {
        animation: none;
        background: silver;
      }
    }

    &:before {
      position: absolute;
      content: ' ';
      top: -24px;
      height: 24px;
      width: 2px;
      background-image: linear-gradient(to bottom, silver 50%, transparent 0%);
      background-size: 8px 4px;
      background-repeat: repeat-y;
      background-position: 0% bottom;

      animation-name: demo-3-before;
      animation-duration: 3s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;

      @include media-breakpoint-down(md) {
        top: 50%;
        left: -0.5rem;
        transform: rotate(90deg);
        height: 1rem;
        width: 1px;
      }

      @include media-breakpoint-down(sm) {
        left: -0.25rem;
        height: 0.5rem;
      }
    }

    &:first-child:before {
      display: none;
    }

    @keyframes demo-3-before {
      0% {
        background-position: 0 0%;
      }
      100% {
        background-position: 0 100%;
      }
    }
  }
}

textarea {
  min-height: 250px;
}

.white-space-pre-line {
  white-space: pre-line;
}

.question-title {
  p {
    font-size: 1rem;
  }
}

.question__list--subtext{
   margin-left: 30px;
  list-style-type: circle;
}

.pre-break {
  white-space: pre-wrap;
  word-break: break-all;
}

.send-variations {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.rating-option {
  width: 100%;

  td:first-child {
    width: 50% !important;
  }
}

.debug-text {
  position: absolute;
  font-size: 0.75rem;
  color: lightgreen;
  left: 17em;
}

.loader-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 8px;
  height: 80px;
}

.insured-sum-row {
  position: relative;

  .insured-sum-tooltip-trigger {
    position: absolute;
    top: 0.5rem;
    right: 0;
  }
}

.insured-sum-option-notification {
  top: 2.5rem;
  right: -300px;

  @include media-breakpoint-down(lg) {
    top: 5rem;
    right: 0;
    left: auto;
  }

  @include media-breakpoint-down(xs) {
    right: 2rem;
  }

  &::before {
    @include media-breakpoint-down(lg) {
      bottom: auto;
      top: -70px;
      left: auto;
      right: 2.5rem;
    }

    @include media-breakpoint-down(xs) {
      right: 0.5rem;
    }
  }

  &::after {
    @include media-breakpoint-down(lg) {
      bottom: auto;
      top: -62px;
      left: auto;
      right: 0.5px;
      width: 42px;
      transform: rotate(90deg);
    }

    @include media-breakpoint-down(xs) {
      right: -2rem;
    }
  }

  ::v-deep  .new-feature__content {
    max-width: 200px;
  }
}

.label-disabled {
  opacity: 0.4;
}
</style>
