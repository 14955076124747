<template>
  <div>
    <!-- <cd-hero :header="header"></cd-hero> -->
    <div id="example_losses_lp" class="secondary-page">
      <div class="container">
        <div class="row page-title">
          <div class="col">
            <h1>Schadenbeispiele</h1>
          </div>

          <div class="col-12 col-md-2 back-btn">
            <router-link class="btn-style" :to="'/vertriebsmaterialien'">&larr;Zurück</router-link>
          </div>

        </div>

      <div class="row">
        <div class="col-12 form-group filtern-list">
          <label>Nach Branche filtern</label>
          <select class="form-control" v-model="selectedIndustry">
            <option>Alles</option>
            <option v-for="(industry, index) in uniqueIndustries" :key="index">{{industry}}</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 loss-vectors" v-for="(vector, index) in industryRender" :key="index" v-if="vector.pdfs.length">

          <div class="row">
            <div class="col-12 col-md-3">
             <div class="vector-container">
                <img :src="'/static/' + vector.image">

             </div>
            </div>

            <div class="col-12 col-md-9">
              <div class="right-container">
                <h3>{{vector.title}}</h3>
                <ul>
                    <li v-for="(pdf, pIndex) in vector.pdfs" :key="pIndex">
                      <a href="" @click.prevent.stop="$openPDF(getURL(pdf.filename))">
                        <icon name="file-pdf" color="secondary"></icon><span>{{pdf.type}}</span>
                      </a>
                    </li>
                  </ul>
              </div>
            </div>
          </div>
              <!-- <hr> -->
        </div>
      </div>


      </div> <!-- end container -->

    </div>

  </div>
</template>

<script>
import Icon from './Icon';

export default {
  name: 'Schadenbeispiele',
  metaInfo: {
    title: 'Schadenbeispiele'
  },
  data () {
    return {
      selectedIndustry: 'Alles',
      uniqueIndustries: [],
      vectors: [
        {
          title: 'Datenbank',
          image: 'datenbank.svg',
          pdfs: [
            {
              industry: 'Hotelerie',
              type: 'Spionage',
              filename: 'CyberDirekt_Schadenbeispiel_Datenbank_Spionage_Hotelerie.pdf'
            },
            {
              industry: 'Handwerker',
              type: 'Erpressung',
              filename: 'CyberDirekt_Schadenbeispiel_Datenbank_Erpressung_Handwerker.pdf'
            },
            {
              industry: 'Handwerker',
              type: 'Bedienungsfehler',
              filename: 'CyberDirekt_Schadenbeispiel_Datenbank _Bedienungsfehler_Handwerker.pdf'
            }
          ]
        },
        {
          title: 'Eigene Webseite',
          image: 'website.svg',
          pdfs: [
            {
              industry: 'Unternehmer',
              type: 'Infizierung',
              filename: 'CyberDirekt_Schadenbeispiel_Eigene_Webseite_Infizierung_Unternehmer.pdf'
            },
            {
              industry: 'Einzelhandel',
              type: 'Erpressung',
              filename: 'CyberDirekt_Schadenbeispiel_Eigene_Webseite_Erpressung_Einzelhandel.pdf'
            }
          ]
        },
        {
          title: 'Hardware',
          image: 'hardware.svg',
          pdfs: [
            {
              industry: 'Unternehmer',
              type: 'Spionage',
              filename: 'CyberDirekt_Schadenbeispiel_Hardware_Spionage_Unternehmer.pdf'
            },
            {
              industry: 'Steuerberater',
              type: 'Erpressung',
              filename: 'CyberDirekt_Schadenbeispiel_Hardware_Erpressung_Steuerberater.pdf'
            }
          ]
        },
        {
          title: 'Öffentliches WLAN',
          image: 'wlan.svg',
          pdfs: [
            {
              industry: 'Hotelerie',
              type: 'Spionage',
              filename: 'CyberDirekt_Schadenbeispiel_Offentliches_WLAN_Spionage_Hotelerie.pdf'
            },
            {
              industry: 'Unternehmer',
              type: 'Erpressung',
              filename: 'CyberDirekt_Schadenbeispiel_Offentliches_WLAN_Erpressung_Unternehmer.pdf'
            }
          ]
        },
        {
          title: 'Social Media',
          image: 'social_media.svg',
          pdfs: [
            {
              industry: 'Personalberater',
              type: 'Spionage',
              filename: 'CyberDirekt_Schadenbeispiel_Social_Media_Spionage_Personalberater.pdf'
            },
            {
              industry: 'Hotelerie',
              type: 'Erpressung',
              filename: 'CyberDirekt_Schadenbeispiel_Social_Media_Erpressung_Hotelerie.pdf'
            }
          ]
        },
        {
          title: 'E-Mail',
          image: 'email.svg',
          pdfs: [
            {
              industry: 'Gastronomie',
              type: 'Spionage',
              filename: 'CyberDirekt_Schadenbeispiel_E-Mail_Spionage_Gastronomie.pdf'
            },
            {
              industry: 'Handwerker',
              type: 'Kontozugriff',
              filename: 'CyberDirekt_Schadenbeispiel_E-Mail_Kontozugriff_Handwerker.pdf'
            },
            {
              industry: 'Arzt',
              type: 'Erpressung',
              filename: 'CyberDirekt_Schadenbeispiel_E-Mail_Erpressung_Arzt.pdf'
            }
          ]
        },
        {
          title: 'Surfen im Internet',
          image: 'internet_surfen.svg',
          pdfs: [
            {
              industry: 'Mitarbeiter',
              type: 'Spionage',
              filename: 'CyberDirekt_Schadenbeispiel_Surfen_im_Internet_Erpressung_Makler.pdf'
            },
            {
              industry: 'Anlageberater',
              type: 'Kreditkartenmissbrauch',
              filename: 'CyberDirekt_Schadenbeispiel_Surfen_im_Internet_Kreditkartenmissbrauch_Anlageberater.pdf'
            },
            {
              industry: 'Hotelerie',
              type: 'Spionage',
              filename: 'CyberDirekt_Schadenbeispiel_Surfen_im_Internet_Spionage_Hotelerie.pdf'
            }
          ]
        },
        {
          title: 'Telefon',
          image: 'telefon.svg',
          pdfs: [
            {
              industry: 'Mitarbeiter',
              type: 'Spionage',
              filename: 'CyberDirekt_Schadenbeispiel_Telefon_Spionage_Mitarbeiter.pdf'
            },
            {
              industry: 'Unternehmer',
              type: 'Kontozugriff',
              filename: 'CyberDirekt_Schadenbeispiel_Telefon_Kontozugriff_Unternehmer.pdf'
            },
            {
              industry: 'Unternehmer',
              type: 'Erpressung',
              filename: 'CyberDirekt_Schadenbeispiel_Telefon_Erpressung_Unternehmer.pdf'
            }
          ]
        }
      ]
    }
  },
  computed: {
    industryRender () {
      if (this.selectedIndustry === 'Alles') {
        return this.vectors
      }

      return this.vectors.map(_v => {
        return {
          title: _v.title,
          image: _v.image,
          pdfs: _v.pdfs.filter(_v => _v.industry === this.selectedIndustry)
        }
      })
    }
  },
  mounted () {
    this.selectedIndustry = this.$route.params.id
    this.loadUniqueIndustries()
  },
  watch: {
    selectedIndustry (newVal) {
      this.$router.push({
        name: 'Schadenbeispiele',
        params: {
          id: newVal
        }
      })
    }
  },
  methods: {
    loadUniqueIndustries () {
      var _inds = []

      this.vectors.forEach(_v => {
        _v.pdfs.forEach(_p => {
          _inds.push(_p.industry)
        })
      })

      _inds = _inds.filter(function (value, index, self) {
        return self.indexOf(value) === index;
      })

      this.uniqueIndustries = _inds
    },
    getURL (filename) {
      return '/static/pdf/schadenbeispiele/' + filename
    }
  },
  components: {
    Icon
  }
}
</script>

<style lang="scss" scoped>

@import '../scss/variables';
@import '../scss/mixins';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.page-title {
  margin-bottom: 0;
}

.filtern-list {
  font-size: 1.2rem;
  padding-bottom: $standardGutter;

  select {
    width: 200px;
    font-size: 1.2rem;
    font-weight: 600;
  }
}

.loss-vectors {
  // border-bottom: 1px solid #ccc;
  padding-bottom: 30px;

  img {
    width: 100px;
  }
  // .l-vector {
    margin-bottom: $standardGutter;

    h3 {
      margin-top: 20px;
    }
    hr {
      width: 50%;
    }

    .right-container {
      padding-left: 40px;
    }
    .vector-container {
      text-align: center;
      // border: 2px solid #ccc;
      padding: 20px;
      border-radius: $borderRadius;
      // height: 192px;
      img {
        // width: 60px;
        // margin-bottom: 13px;
      }
    }

    ul {
        padding: 0;
        list-style-type: none;
        li {
          text-align: left;
          margin-bottom: 4px;
          a {
            color: $orangeTone;
            span {
              position: relative;
              top: 3px;
              left: 7px;
            }
          }
        }
      }
  // }

    @include media-breakpoint-down(sm) {
      text-align: center;
      > div:first-child {
        border-right: 0;
      }
      .right-container {
        padding-left: 0;
      }
      ul {
        li {
          text-align: center;
        }
      }
    }
}

// .loss-vectors:nth-child(odd) {
//  > div:first-child {
//     border-right: 1px solid #ccc;
//   }
// }
</style>
