<template>
<div class="container login-container">
  <form @submit.prevent.stop="submitForm()" name="forgot" class="login-form">
    <h1 class="login-title">Passwort vergessen?</h1>

    <div class="form-group form-group--icon-l" v-if="!twoFactorAuth">
      <Icon name="mail" width="20" class="icon icon-l"></Icon>
      <b-form-input type="email" id="email" class="form-trol" v-model="user.email" placeholder="E-Mail" required></b-form-input>
    </div>
    
    <ButtonComponent
        btnStyle="accent"
        behavior="button"
        class="submit-btn"
        >Passwort zurücksetzen</ButtonComponent>
      
    
    <div class="login-form__bottom-links text-center justify-content-center">
      <ButtonComponent
        btnStyle="link-primary"
        behavior="internalLink"
        link="/login/authenticate"
        type="button"
        >Abbrechen</ButtonComponent>
    </div>
  </form>
    <CustomToasted ref="customToasted" class="custom-toasted-container"></CustomToasted>
</div>
</template>

<script>
import ButtonComponent from '@/components/reusable/ButtonComponent'
import Icon from '@/components/Icon.vue' 
import CustomToasted from '@/components/customToasted'

export default {
  name: 'Forgot',
  components: {ButtonComponent, Icon, CustomToasted},

  metaInfo: {
    title: 'Passwort vergessen'
  },
  props: ['user'],
  data () {
    return {
    }
  },
  mounted: function () {
  },
  methods: {
    submitForm: function () {
      this.$store
        .dispatch('FORGOT', this.user.email)
        .then((response) => {
          this.$refs.customToasted.show({
            message: 'E-Mail wurde versandt',
            type: 'success',
            duration: 3000
          })
          setTimeout(() => {
            this.$router.push('/login/authenticate');
          }, 3000)
        })
        .catch(err => {
          let message = err.response.data.error.message

          if (err.response.data.error.code === "LOGIN_FAILED") {
            message = "Falsche Zugangsdaten. Bitte überprüfen Sie Ihre Eingabe."
          }

          this.$refs.customToasted.show({
            message,
            type: 'error',
            duration: 3000
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/variables';
@import '../../scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';


.login-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.login-form {
  background: #fff;
  border-radius: 0.5rem;
  color: #454545;
  padding: 2.5rem 2.5rem 2.5rem 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @include media-breakpoint-down(xs) {
    padding: 2rem 1.5rem;
  }

  .login-top {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;

    ::v-deep button {
      svg {
        margin-left: -0.5rem;
        margin-right: 0.25rem;
      }
    }
  }

  .form-group {
    width: 100%;
    margin-bottom: 1.5rem;

    &--icon-l {
      position: relative;

      .icon-l {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 14px;

        ::v-deep svg {
          color: $orangeTone;
        }
      }

      .form-control {
        padding-left: 2.75rem;
      }
    }

    &--icon-r {
      position: relative;

      .icon-r {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 14px;

        ::v-deep svg {
          color: #454545;
        }
      }

      .form-control {
        padding-right: 2.75rem;
      }
    }

    .icon-primary {
      ::v-deep svg {
        color: $primary;
      }
    }
  }

  .form-control {
    border: 1px solid #ced9dfe5;
    background: #CED9DF33;
    height: 40px;
      font-size:  0.9rem;
  }

  .input-group-text {
    background: #CED9DF33;
    border-right: none;
    padding-right: 0;
  }

  .input-group-prepend {
    margin-right: 0;

    & + .form-control {
      border-left: none;
    }
  }

  .submit-btn {
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    width: 100%;
  }

  &__bottom-links {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
  }
}


.login-title {
  font-size: 1.25rem;
  font-weight: 800;
  margin-bottom: 2rem;
}

.custom-toasted-container {
  margin-top: 2rem;
}

</style>
